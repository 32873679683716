import React from "react";


import HorizontalChartCard from "pages/LocationInsights/components/charts/HorizontalChartCard";

import { getPercentageString } from "../../../utils";

const TbhJobFunctions = ({ title, data = {}, location, max = 5 }) => {
  const totalValue = Object.values(data).reduce((acc, curr) => acc + +curr, 0);

  const sortedData = Object.entries(data).map(([key, value]) => ({
    label: key,
    value: +value,
    additionalValue: getPercentageString(+value, totalValue)
  })).sort((a, b) => b.value - a.value);

  const top = sortedData.slice(0, max);
  const others = sortedData.slice(max);

  const othersValue = others.reduce((acc, curr) => acc + curr.value, 0);

  let chartData = top;

  if (othersValue > 0) {
    chartData = chartData.concat({
      label: "Others",
      value: othersValue,
      additionalValue: getPercentageString(othersValue, totalValue)
    });
  }

  return <HorizontalChartCard header={title} data={chartData} color="#0F62FE" />;
};

export default TbhJobFunctions;

