import { useState, useEffect } from "react";

import { subscribe, unsubscribe } from "utils/events";

const useEventState = (eventName) => {
  const [isEventLoading, setIsEventLoading] = useState(false);

  useEffect(() => {
    const handleEventStart = () => setIsEventLoading(true);
    const handleEventEnd = () => setIsEventLoading(false);

    subscribe(`${eventName}:Start`, handleEventStart);
    subscribe(`${eventName}:End`, handleEventEnd);

    return () => {
      unsubscribe(`${eventName}:Start`, handleEventStart);
      unsubscribe(`${eventName}:End`, handleEventEnd);
    };
  }, [eventName]);

  return isEventLoading;
};

export default useEventState;
