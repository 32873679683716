import React from "react";

import PieChartCard from "pages/LocationInsights/components/charts/PieChartCard";
import { getPercentageString } from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/LocationInsightsInternalDetail/utils";

const colors = ["#BFBFBF", "#570408", "#FA4D56", "#9F1853"];

const ExperienceRanking = ({ data }) => {
  const totalValue = data.reduce((acc, item) => acc + +item.demandcount, 0);
  const chartData = data.map((item, index) => ({
    label: item.name,
    value: item.demandcount,
    additionalValue: getPercentageString(item.demandcount, totalValue),
    color: colors[item.id] || colors[index % colors.length],
  }));
  return (
    <PieChartCard header="Experience in years" data={chartData} />
  );
};

export default ExperienceRanking;
