import React from "react";

import PieChartCard from "pages/LocationInsights/components/charts/PieChartCard";

import { getPercentageString } from "../../../utils";

const COLOR_MAP = {
  "Hybrid": "#009D9A",
  "Remote": "#3DDBD9",
  "Office": "#004144",
};

const HeadcountFlexElection = ({ data = {} }) => {
  const keyOrder = Object.keys(COLOR_MAP);
  const sortedData = Object.entries(data).sort((a, b) => keyOrder.indexOf(a[0]) - keyOrder.indexOf(b[0]));

  const totalValue = Object.values(data).reduce((acc, curr) => acc + +curr, 0);
  
  const chartData = sortedData.map(([key, value]) => ({
    label: key,
    value: +value,
    color: COLOR_MAP[key],
    additionalValue: getPercentageString(+value, totalValue),
  }));

  return (
    <PieChartCard header="Headcount Flex Election" data={chartData} />
  );
};

export default HeadcountFlexElection;
