import AutodeskImg from "img/favicon.png";
import { StatCard } from "pages/LocationInsights/components/StatCard";
import { useSearchParams, useParams } from "react-router-dom";


const LeadershipDepthRatio = ({ data, locationRatio, teamRatio, showUrl = true, theme = "light" }) => {
  const value = data?.value;

  const ratio = locationRatio?.value || "";
  const globalRatio = teamRatio?.value || "";
  const level = value?.toLowerCase();

  const [currentParams] = useSearchParams();
  const { id } = useParams();

  return <StatCard
    mainStat={ratio}
    mainStatLabel={{ title: "Team leadership depth" }}
    subStats={[{
      value: "Director & above: Employees",
      title: globalRatio,
      icon: <img src={AutodeskImg} alt="Autodesk" className="aspect-square h-3 w-3" />,
    }]} 
    level={level}
    theme={theme}
    url={showUrl ? `/dashboard/${id}/location-insights/internal-view/leadership-depth?${currentParams.toString()}` : undefined}
  />;
};

export default LeadershipDepthRatio;
