import React from "react";

import PieChartCard from "pages/LocationInsights/components/charts/PieChartCard";

import { getPercentageString } from "../../../utils";

const COLOR_MAP = {
  "AMER": "#EE538B",
  "APAC": "#1292E8",
  "EMEA": "#6929C4",
};

const HeadcountGeoDistribution = ({ data = [] }) => {
  const totalValue = data.reduce((acc, curr) => acc + +curr.value, 0);
  const chartData = data.map(({ category, value }) => ({
    label: category,
    value: +value,
    color: COLOR_MAP[category],
    additionalValue: getPercentageString(+value, totalValue),
  }));

  return <PieChartCard data={chartData} header="Geo distribution" />;
};

export default HeadcountGeoDistribution;
