import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { insightApi } from "../../apis/api";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const insightsGenie = insightApi.injectEndpoints({
  endpoints: (builder) => ({
    getInsightsGenie: builder.query({
      query: () => ({
        url: "/insights-genie",
        params: {
          token: Cookies.get("storedAccessToken"),
        }
      }),
    }),
    getExcelDownloadUrl: builder.query({
      query: () => ({
        url: "/generate-file",
      }),
    }),
    getInsightsGenieData: builder.query({
      query: (params) => ({
        url: "/insight-genie-data",
        params: {
          token: Cookies.get("storedAccessToken"),
          ...params
        }
      }),
    }),
    getInsightsGenieDomains: builder.query({
      query: (params) => ({
        url: "/application-filters",
        params: {
          token: Cookies.get("storedAccessToken"),
          ...params
        }
      }),
    }),
    getInsightsGenieFilters: builder.query({
      query: (params) => ({
        url: "/application-filters",
        params: {
          token: Cookies.get("storedAccessToken"),
          ...params
        }
      }),
    }),
    getInsightsJobStatus: builder.query({
      query: (params) => ({
        url: "/status",
        params: {
          token: Cookies.get("storedAccessToken"),
          ...params
        }
      }),
    }),
  }),
});

export const insightsGenieSlice = createSlice({
  name: "insights-genie",
  initialState,
});

export const {
  useGetInsightsGenieQuery,
  useGetExcelDownloadUrlQuery,
  useLazyGetExcelDownloadUrlQuery,
  useLazyGetInsightsGenieDataQuery,
  useLazyGetInsightsJobStatusQuery,
  useGetInsightsGenieDomainsQuery,
  useLazyGetInsightsGenieFiltersQuery,
} = insightsGenie;
