import React from "react";

import { DataTable } from "components/DataTable";

const columns = [
  {
    key: "country_code",
    label: "Country",
  },
  {
    key: "city",
    label: "City",
  },
  {
    key: "hiringDifficulty",
    label: "Hiring Difficulty",
  },
];

const OtherLocationsHiringDifficulty = ({ data }) => {

  return (
    <DataTable data={data} groups={columns} />
  );
};

export default OtherLocationsHiringDifficulty;

