import React, { useState } from "react";

import { Chip, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DataTable } from "components/DataTable";
import DownloadExcelButton from "pages/LocationInsights/components/DownloadExcelButton";

const columns = [
  {
    key: "geo",
    label: "Region",
    isSortable: true,
  },
  {
    key: "country",
    label: "Country",
    isSortable: true,
  },
  {
    key: "location",
    label: "Location",
    isSortable: true,
  },
  {
    key: "autodesk_tbh",
    label: "Autodesk TBH",
    isSortable: true,
  },
  {
    key: "team_tbh",
    label: "Team TBH",
    isSortable: true,
  },
  {
    key: "common",
    label: "",
    isSortable: false,
  }
];

const TopLocations = ({ autodeskData, teamData }) => {
  const [showCommon, setShowCommon] = useState(false);
  const fy = autodeskData ? Object.values(autodeskData)?.[0]?.fy : "";

  const allData = Object.entries(autodeskData).map(([key, value]) => {
    const [geo, country, location] = key.split("-").map(item => item.trim());
    return {
      geo: geo,
      country: country,
      location: location,
      autodesk_tbh: value.total_count,
      team_tbh: teamData[key],
      common: teamData[key] ? <Chip label="Common" style={{ background: "#5F60FF", color: "white" }} /> : null,
      hightlight: teamData[key] ? true : false,
      highlightColor: "#EDF5FF"
    };
  });

  return (
    <div className="flex flex-col gap-6 w-full">
      <div className="flex items-center gap-6">
        <p className="text-xl font-extrabold">{fy}: Top locations</p>
        <ToggleButtonGroup size="small" className="mr-auto">
          <ToggleButton value="autodesk" selected={!showCommon} onClick={() => setShowCommon(false)}>All</ToggleButton>
          <ToggleButton value="team" selected={showCommon} onClick={() => setShowCommon(true)}>Common with team</ToggleButton>
        </ToggleButtonGroup>
        <DownloadExcelButton data={allData.map(data => ({
          geo: data.geo,
          country: data.country,
          location: data.location,
          autodesk_tbh: data.autodesk_tbh,
          team_tbh: data.team_tbh,
        }))} columns={columns} title={`${fy} Top locations`} />
      </div>
      <DataTable data={showCommon ? allData.filter(data => data.common) : allData} groups={columns} />
    </div>
  );
};

export default TopLocations;
