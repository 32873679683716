import React from "react";

import { FormControl, InputLabel, Link, MenuItem, Select, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useSearchParams } from "react-router-dom";

const LocationFilter = ({ filterOptions }) => {
  const [currentParams, setCurrentParams] = useSearchParams();

  const handleChangeGeo = (event) => {
    currentParams.set("geo", event.target.value);
    currentParams.delete("country");
    currentParams.delete("city");
    setCurrentParams(currentParams);
  };

  const handleChangeCountry = (event) => {
    const value = event.target.value;
    if(!value) {
      currentParams.delete("country");
    } else {
      currentParams.set("country", value);
    }
    currentParams.delete("city");
    setCurrentParams(currentParams);
  };

  const handleChangeCity = (event) => {
    const value = event.target.value;
    if(!value) {
      currentParams.delete("city");
    } else {
      currentParams.set("city", value);
    }
    setCurrentParams(currentParams);
  };

  const geo = currentParams.get("geo") || "";
  const country = currentParams.get("country") || "";
  const city = currentParams.get("city") || ""; 

  const geoOptions = filterOptions?.geo ? filterOptions?.geo : [];
  const countryOptions = filterOptions?.country && geo ? filterOptions.country[geo] : [];
  const cityOptions = filterOptions?.city && country ? filterOptions.city[country] : [];

  const handleClickClear = () => {
    currentParams.delete("geo");
    currentParams.delete("country");
    currentParams.delete("city");
    setCurrentParams(currentParams);
  };

  /* useEffect(() => {
    if (geoOptions.length > 0 && !geo && currentParams) {
      currentParams.set("geo", geoOptions[0]);
      setCurrentParams(currentParams);
    }
  }, [geoOptions.length, geo, currentParams]);

  useEffect(() => {
    if (countryOptions.length > 0 && !country) {
      currentParams.set("country", countryOptions[0]);
      setCurrentParams(currentParams);
    }
  }, [countryOptions, country]);

  useEffect(() => {
    if (cityOptions.length > 0 && !city) {
      currentParams.set("city", cityOptions[0]);
      setCurrentParams(currentParams);
    }
  }, [cityOptions, city]); */

  return (
    <Box>
      <div className="flex items-center justify-between">
        <Typography variant="body1" gutterBottom>Location</Typography>
        <Link
          component="button"
          variant="body1"
          onClick={handleClickClear}
          underline="always"
        >
          Reset
        </Link>
      </div>
      <Box sx={{ display: "flex", gap: 2 }}>
        <FormControl sx={{ flexGrow: 1, flexBasis: 0, backgroundColor: "white" }} size="small">
          <InputLabel id="geo-select-small-label">Geo</InputLabel>
          <Select
            labelId="geo-select-small-label"
            id="geo-select-small"
            label="Geo"
            value={geo}
            onChange={handleChangeGeo}
          >
            {[...geoOptions]?.sort((a, b) => a.localeCompare(b)).map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ flexGrow: 1, flexBasis: 0, backgroundColor: "white" }}>
          <InputLabel id="country-select-small-label">Country</InputLabel>
          <Select
            labelId="country-select-small-label"
            id="country-select-small"
            label="Country"
            value={country}
            onChange={handleChangeCountry}
          >
            {[...countryOptions]?.sort((a, b) => a.localeCompare(b)).map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ flexGrow: 1, flexBasis: 0, backgroundColor: "white" }}>
          <InputLabel id="city-select-small-label">City</InputLabel>
          <Select
            labelId="city-select-small-label"
            id="city-select-small"
            label="City"
            value={city}
            onChange={handleChangeCity}
          >
            {[...cityOptions]?.sort((a, b) => a.localeCompare(b)).map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default LocationFilter;
