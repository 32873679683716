import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { ColorTheme3 } from "theme/chart";

const convertToChartData = (data) => {
  // Extract unique quarters
  const quarters = Object.keys(data.voluntary ?? {}).sort();

  // Convert the object to an array of objects suitable for the chart
  const chartData = quarters.map(quarter => ({
    quarter: `FY${quarter.slice(2, 4)} - ${quarter.slice(5)}`,
    voluntary: +data.voluntary[quarter],
    involuntary: +data.involuntary[quarter],
    total: +data.total[quarter]
  }));

  return chartData;
};

const AttritionTrends = ({ data }) => {
  return (
    <div>

      <p className="text-xl font-extrabold mb-1">Attrition trends</p>

      <div className="flex items-center mb-6 gap-2">
        <div className="flex items-center gap-1">
          <div className="w-3 h-3 rounded-full" style={{ backgroundColor: "#ccc" }}></div>
          <p className="text-sm">Total attrition</p>
        </div>
        <div className="flex items-center gap-1">
          <div className="w-3 h-3 rounded-full" style={{ backgroundColor: "#FF7EB6" }}></div>
          <p className="text-sm">Voluntary attrition</p>
        </div>
        <div className="flex items-center gap-1">
          <div className="w-3 h-3 rounded-full" style={{ backgroundColor: "#A56EFF" }}></div>
          <p className="text-sm">Involuntary attrition</p>
        </div>
      </div>
      <AttritionTrendsChart data={data} />
    </div>
  );
};

export default AttritionTrends;

const AttritionTrendsChart = ({ data }) => {
  const chartData = convertToChartData(data);
  
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");
    root._logo.dispose();

    root.setThemes([
      ColorTheme3.new(root)
    ]);

    let container = root.container.children.push(am5.Container.new(root, {
      layout: root.verticalLayout,
      width: am5.percent(100),
      height: am5.percent(100),
    }));

    let chart = container.children.push(am5xy.XYChart.new(root, {
      height: am5.percent(100), // Adjust the height to make space for the legend
    }));

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
    xRenderer.labels.template.setAll({
      fill: am5.color("#666666"),
      textAlign: "center",
      fontSize: 12,
    });
    xRenderer.grid.template.set("visible", false);
    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0.3,
      categoryField: "quarter",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }));

    const yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.labels.template.setAll({
      fill: am5.color("#666666"),
      textAlign: "center",
      fontSize: 12,
    });
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: yRenderer,
      min: 0,
      numberFormat: "#.00'%'",
    }));


    yAxis.get("renderer").labels.template.setAll({
      text: "{value.formatNumber('#.00')}%"
    });

    xAxis.data.setAll(chartData);

    function createSeries(name, field, dashed, color) {
      let series = chart.series.push(am5xy.LineSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: field,
        categoryXField: "quarter",
        stroke: am5.color(color ?? "#000000"),
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY.formatNumber('#.0')}%",
        })
      }));

      series.data.setAll(chartData);

      series.strokes.template.setAll({
        strokeWidth: 3
      });

      if (dashed) {
        series.strokes.template.set("strokeDasharray", [5, 3]);
      }

      series.appear();
    }

    createSeries("Voluntary", "voluntary", false, "#FF7EB6");
    createSeries("Involuntary", "involuntary", false, "#A56EFF");
    createSeries("Total", "total", true, "#ccc");

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <>
      <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
    </>
  );
};

