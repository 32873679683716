import React, { useLayoutEffect } from "react";


import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

function sortByLabels(a, b) {
  const getFirstNumber = (label) => {
    const match = label.match(/^\d+/); 
    return match ? parseInt(match[0], 10) : Infinity; 
  };

  return getFirstNumber(a.quarter) - getFirstNumber(b.quarter);
}


const convertToChartData = (data) => {
  // Extract unique quarters
  const quarters = Object.keys(data.voluntary ?? {}).sort();

  // Convert the object to an array of objects suitable for the chart
  const chartData = quarters.map(quarter => ({
    quarter: `${quarter} years`,
    voluntary: +data.voluntary[quarter],
    involuntary: +data.involuntary[quarter],
    total: +data.total[quarter]
  })).sort(sortByLabels);  

  return chartData;
};

const AttritionByTenure = ({ data }) => {
  return (
    <div>
      <p className="text-xl font-extrabold mb-1">Annualized Attrition by Tenure</p>
      <div className="flex items-center mb-6 gap-2">
        <div className="flex items-center gap-1">
          <div className="w-3 h-3 rounded-full" style={{ backgroundColor: "#ccc" }}></div>
          <p className="text-sm">Total attrition</p>
        </div>
        <div className="flex items-center gap-1">
          <div className="w-3 h-3 rounded-full" style={{ backgroundColor: "#FF7EB6" }}></div>
          <p className="text-sm">Voluntary attrition</p>
        </div>
        <div className="flex items-center gap-1">
          <div className="w-3 h-3 rounded-full" style={{ backgroundColor: "#A56EFF" }}></div>
          <p className="text-sm">Involuntary attrition</p>
        </div>
      </div>
      <AttritionByTenureChart data={data} />
    </div>
  );
};

export default AttritionByTenure;

const AttritionByTenureChart = ({ data }) => {
  const chartData = convertToChartData(data);

  useLayoutEffect(() => {
    let root = am5.Root.new("attrition-tenure");
    root._logo.dispose();

    const theme = am5.Theme.new(root);
    theme.rule("ColorSet").setAll({
      colors: [
        am5.color("#ccc"),
        am5.color("#FF7EB6"),
        am5.color("#A56EFF"),
      ],
      reuse: true
    });

    root.setThemes([am5themes_Animated.new(root), theme]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {})
    );

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);
    
    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
    
    xRenderer.setAll({
      cellStartLocation: 0.2,
      cellEndLocation: 0.8,
    });
    
    xRenderer.labels.template.setAll({
      fill: am5.color("#666666"),
      textAlign: "center",
      fontSize: 12,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "quarter",
        renderer: xRenderer,
      })
    );

    xRenderer.grid.template.setAll({
      location: 1
    });

    xAxis.data.setAll(chartData);

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      numberFormat: "#.0'%'",
      min: 0,
      renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1
      })
    }));

    yAxis.get("renderer").labels.template.setAll({
      fill: am5.color("#666666"),
      textAlign: "center",
      fontSize: 12,
    });

    function makeSeries(name, fieldName) {
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: fieldName,
        categoryXField: "quarter"
      }));
    
      series.columns.template.setAll({
        tooltipText: "{name}, {categoryX}: {valueY}",
        width: am5.percent(100),
        cornerRadiusTL: 5,
        cornerRadiusTR: 5
      });
      series.data.setAll(chartData);
    
      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();
    
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 1.05,
          sprite: am5.Label.new(root, {
            centerX: am5.p50,
            centerY: am5.p50,
            text: "{valueY}",
            fill: am5.color("#666666"),
            fontSize: 12,
            populateText: true
          })
        });
      });

    }

    makeSeries("Total", "total");
    makeSeries("Voluntary", "voluntary");
    makeSeries("Involuntary", "involuntary");

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <div id="attrition-tenure" style={{ width: "100%", height: "500px" }}></div>
  );
};
