import React from "react";

import HorizontalChartCard from "pages/LocationInsights/components/charts/HorizontalChartCard";

import { getPercentageString } from "../../../utils";

const COLOR_MAP = {
  "Regular Employees": "#1292E8",
  "Interns/Coop": "#1292E8",
  "Fixed Terms": "#1292E8",
  "Agency Temps": "#198038",
  "Independent Contractors": "#198038",
  "Outside Service Providers": "#B28600",
  "Associates": "#B28600",
};

const HeadcountTypeBreakdown = ({ data = {} }) => {
  const keyOrder = Object.keys(COLOR_MAP);
  const sortedData = Object.entries(data).sort((a, b) => keyOrder.indexOf(a[0]) - keyOrder.indexOf(b[0]));

  const totalValue = Object.values(data).reduce((acc, curr) => acc + +curr, 0);

  const chartData = sortedData.map(([key, value]) => ({
    label: key,
    value: +value,
    additionalValue: getPercentageString(+value, totalValue),
    color: COLOR_MAP[key]
  }));

  return <HorizontalChartCard header="Worker type breakdown" data={chartData} />;
};

export default HeadcountTypeBreakdown;
