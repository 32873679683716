import React from "react";

import { Box, Grid, Link } from "@mui/material";
import HeaderCard from "pages/LocationInsights/components/HeaderCard";
import { StatCard } from "pages/LocationInsights/components/StatCard";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import AverageTenure from "./AverageTenure";
import LeadershipDepthCount from "./LeadershipDepthCount";
import LeadershipDepthLocation from "./LeadershipDepthLocation";
import { getLocationNameFromQueryParams } from "../../../utils";
import LeadershipDepthRatio from "../LeadershipDepthRatio";

const LeadershipDepthDetail = () => {
  const [currentParams] = useSearchParams();
  const { data, isLoading } = useOutletContext();
  const locationName = getLocationNameFromQueryParams(currentParams);

  const siteLeader = data?.metrics?.find((item) => item.metric_name === "site_leader");
  const leadershipDepth = data?.metrics?.find((item) => item.metric_name === "leadership_depth");
  const leadershipDepthTeam = data?.adsk_location_metrics?.find((item) => item.metric_name === "headcount_mgmt_level");
  const leadershipDepthLocation = data?.metrics?.find((item) => item.metric_name === "headcount_mgmt_level");
  const leadershipCounts = data?.metrics?.find((item) => item.metric_name === "div_leadership_distribution");
  const locationLeadershipDepthRatios = data?.metrics?.find((item) => item.metric_name === "leadership_depth_ratios");
  const teamLeadershipDepthRatios = data?.adsk_location_metrics?.find((item) => item.metric_name === "leadership_depth_ratios");
  const averageTenureTeam = data?.adsk_location_metrics?.find((item) => item.metric_name === "leadership_avg_tenure");
  const averageTenureLocation = data?.metrics?.find((item) => item.metric_name === "leadership_avg_tenure");
  const leaderShipStaff = data?.metrics?.find((item) => item.metric_name === "leadership_staff");

  if(isLoading) {
    return (
      <Grid item md={12}>
        <Box className="flex items-center justify-center w-full" sx={{ height: "calc(100vh - 400px)" }}>
          <BeatLoader color="#5C5470" />
        </Box>
      </Grid>
    );
  }

  return (
    <Box>
      <HeaderCard
        question={"What is this location's leadership depth?"}
        info={<span>
            The Site Leader information in this tool is reviewed quarterly. Please see the <Link target="_blank" sx={{ fontWeight: "bold" }} href="https://share.autodesk.com/sites/WPT/SitePages/Office-Site-Leaders.aspx">WPT Sharepoint site</Link> for the latest information.
        </span>}
      />
      <Grid container columnSpacing={4}>
        <Grid item lg={4} xs={12}>
          <div className="flex flex-col gap-4">
            <LeadershipDepthRatio
              data={leadershipDepth}
              locationRatio={locationLeadershipDepthRatios}
              teamRatio={teamLeadershipDepthRatios}
              showUrl={false}
              theme="dark"
            />

            {
              siteLeader?.value !== "N/A" ? <div className="flex flex-col gap-4">
                <StatCard mainStat={siteLeader?.value} mainStatLabel={{ title: "Site leader" }}/>
              </div> : null
            }
          </div>
        </Grid>

        <Grid item lg={4} xs={12}>
          <LeadershipDepthLocation teamValue={leadershipDepthTeam?.value ?? {}} locationValue={leadershipDepthLocation?.value ?? {}} locationName={locationName}/>
        </Grid>

        <Grid item lg={4} xs={12}>
          <AverageTenure team={averageTenureTeam?.value} location={averageTenureLocation?.value} />
        </Grid>

        <Grid item lg={12} xs={12} mt={4}>
          {leadershipCounts && (
            <LeadershipDepthCount locationValue={leadershipCounts.value} leaderShipStaff={leaderShipStaff.value ?? {}} />
          )}
        </Grid>
        {/* <Grid item lg={6} xs={12} display="flex" alignItems="center">
          <LeadershipDepth leadershipDepthValue={leadershipDepth?.value} leadershipDepthRatios={leadershipDepthRatios?.value} />
        </Grid>
        <Grid item lg={6} xs={12}>
          <SiteLeader data={siteLeader?.value} />
        </Grid>
        <Grid item lg={6} xs={12}>
          {leadershipDepthTeam && leadershipDepthLocation && (
            <LeadershipDepthLocation teamValue={leadershipDepthTeam?.value} locationValue={leadershipDepthLocation?.value} locationName={locationName}/>
          )}
        </Grid>
        <Grid item lg={6} xs={12}>
          <AverageTenure team={averageTenureTeam?.value} location={averageTenureLocation?.value} />
        </Grid>
        <Grid item lg={12} xs={12}>
          {leadershipCounts && (
            <LeadershipDepthCount locationValue={leadershipCounts.value} />
          )}
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default LeadershipDepthDetail;
