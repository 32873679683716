import React from "react";

import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const DownloadExcel = ({ data, fileName, label, component }) => {

  const downloadExcel = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    // Add a worksheet to the workbook
    XLSX.utils.book_append_sheet(
      wb,
      XLSX.utils.json_to_sheet(data),
      "Sheet 1"
    );
      
    // Write the workbook to an array buffer
    const arrayBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      
    // Create a Blob from the array buffer
    const blob = new Blob([arrayBuffer], {
      type: "application/octet-stream",
    });
      
    // Use the FileSaver.js library to save the Blob as a file
    saveAs(blob, `${fileName ?? "data"}.xlsx`);
  };
  

  return component ? <div onClick={downloadExcel}>{component}</div> : (
    <Button startIcon={<Download />} onClick={downloadExcel} disabled={!data || data.length === 0}>
      {label}
    </Button>
  );
};

export default DownloadExcel;
