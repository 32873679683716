import React, { useEffect } from "react";

import { Autocomplete, FormControl, TextField, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const JobFunctionFilter = ({ jobOptions }) => {
  const [currentParams, setCurrentParams] = useSearchParams();
  
  const job = currentParams.get("job");

  const handleChangeJob = (value) => {
    if(!value) {
      currentParams.delete("job");
    } else {
      currentParams.set("job", value);
    }
    setCurrentParams(currentParams);
  };

  useEffect(() => {
    if (job && !jobOptions.includes(job)) {
      currentParams.delete("job");
      setCurrentParams(currentParams
      );
    }
  }, []);

  return (
    <>
      <Typography variant="body1" gutterBottom>Job Function</Typography>
      <FormControl size="small" sx={{ width :"100%", backgroundColor: "white" }}>
        <Autocomplete
          value={job}
          onChange={(_e, values)=>handleChangeJob(values)}
          size="small"
          ChipProps={{
            variant: "contained",
            color: "primary",
          }}
          options={[...jobOptions]?.sort((a, b) => a.localeCompare(b)) || []}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Job Function"
            />
          )}
        />
      </FormControl>
    </>
  );
};

export default JobFunctionFilter;
