import { OpenInNew } from "@mui/icons-material";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import LevelIndicator from "./LevelIndicator";

/* interface Props {
    mainStat: string | number;
    mainStatLabel: {
        title: string;
        value: string;
        icon: React.ReactNode;
    };
    subStats: {
        title: string;
        value: string | number;
        icon: React.ReactNode
    }[];
    level: 'low' | 'medium' | 'high' | 'critical';
    levelConfig?: {
      inverse?: boolean;
      solid?: boolean;
    },
    theme: 'light' | 'dark';
    className: string;
    url?: string;
} */
export const StatCard = ({ mainStat, mainStatLabel = {}, subStats = [], level, levelConfig, theme = "light", className, url }) => {
  const navigate = useNavigate();
  return <div onClick={() => {
    if (url) {
      navigate(url);
    }
  }} className={clsx("p-6 border rounded-3xl flex items-center group", {
    "bg-white": theme === "light",
    "bg-black": theme === "dark",
    "border-[#e5e5e5]": theme === "light",
    "border-[#333]": theme === "dark",
    "hover:border-black cursor-pointer": url,
    "hover:bg-black/90": theme === "dark" && url,
  }, className)}>
    <div className="flex flex-col gap-2">
      <div className="flex items-center">
        <p className={clsx("text-4xl font-extrabold mr-3", {
          "text-black": theme === "light",
          "text-white": theme === "dark",
        })}>{mainStat}</p>
        
        {
          subStats.map((subStat) => (
            <div key={subStat.title} className={clsx("flex items-center gap-1 p-2 rounded mr-1", { "bg-[#f9f9f9]": theme === "light", "bg-[#161616]": theme === "dark" })}>
              {subStat.icon}
              <p className={clsx({
                "text-black": theme === "light",
                "text-white": theme === "dark",
              })}>{subStat.title}</p>
              <p className={clsx("text-xs", {
                "text-black/60": theme === "light",
                "text-white/60": theme === "dark",
              })}>{subStat.value}</p>
            </div>
          ))
        }  
      </div>
      
      <div className={clsx("flex items-center gap-1")}>
        {mainStatLabel.icon}
        <p className={clsx({
          "text-black": theme === "light",
          "text-white": theme === "dark",
          "group-hover:underline": url
        })}>{mainStatLabel.title}
        </p>
        <div className={clsx("hidden", { "group-hover:block": url, "text-black": theme === "light",
          "text-white": theme === "dark", })}><OpenInNew style={{ fontSize: "16px" }} /></div>
        <p className={clsx("text-sm", {
          "text-black/60": theme === "light",
          "text-white/60": theme === "dark",
          "group-hover:invisible": url
        })}>{mainStatLabel.value}</p>
      </div>
      
    </div>
    {
      level ? <LevelIndicator level={level} theme={theme} config={levelConfig} />: null
    }
  </div>;
};
