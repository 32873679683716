import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import HeaderCard from "pages/LocationInsights/components/HeaderCard";
import { useOutletContext } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import EducationRanking from "./EducationRanking";
import ExperienceRanking from "./ExpereinceRanking";
import SkillsRanking from "./SkillsRanking";
import TitlesRanking from "./TitlesRanking";

const RoleAnalysisDetail = () => {
  const { externalData, isLoading } = useOutletContext();
  
  if(isLoading) {
    return (
      <Grid item md={12}>
        <Box className="flex items-center justify-center w-full" sx={{ height: "calc(100vh - 400px)" }}>
          <BeatLoader color="#5C5470" />
        </Box>
      </Grid>
    );
  }
    
  if(!externalData || externalData.length !== 1) {
    return (
      <Box className="w-full" sx={{ height: "calc(100vh - 400px)" }}>
        <div className="w-3/4 mx-auto">
          <Typography mt={4} variant="body1" textAlign="center" fontWeight="bold" fontSize="24px">
              No data available. Try selecting or changing the location and job title filters
          </Typography>
        </div>
      </Box>
    );
  } 

  const data = externalData[0];
  return (
    <Box>
      <HeaderCard 
        question="What qualifications are in demand in this location?"
        info="This page shows the skills, job titles, education and experience that are most in-demand for this role, at this location."
      />
      <Grid container mt={4} columnSpacing={4}>
        <Grid item lg={4}>
          <div className="flex flex-col gap-6 w-full">
            <EducationRanking data={data.details_education_ranking} />
            <TitlesRanking data={data.details_titles_ranking} />
          </div>
        </Grid>
        <Grid item lg={4}>
          <div className="flex flex-col gap-6 w-full">
            <ExperienceRanking data={data.details_experience_ranking} />
            <SkillsRanking data={data.details_skills_ranking} />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RoleAnalysisDetail;
