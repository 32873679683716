
import { useMemo } from "react";

import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InputOutlinedIcon from "@mui/icons-material/InputOutlined";
import OutputOutlinedIcon from "@mui/icons-material/OutputOutlined";
import { useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

const getUrl = (pathname, url, params) => {
  params.delete("s");
  if((pathname.includes("external-view") && !url.includes("external-view")) 
    || (pathname.includes("internal-view") && !url.includes("internal-view"))) {
    const newParams = new URLSearchParams();
    const geo = params.get("geo");
    if(geo) {
      newParams.set("geo", geo);
    }
    
    const country = params.get("country");
    if(country) {
      newParams.set("country", country);
    }
    
    const city = params.get("city");
    if(city) {
      newParams.set("city", city);
    }
    return `${url}?${newParams.toString()}`;
  }

  return `${url}?${params.toString()}`;
};

const useMenus = () => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();
  const { pathname } = useLocation();
  const { externalData } = useSelector((state) => state.location);
  const menus = useMemo(() => getMenus(id, externalData?.[0]?.has_diversity), [id, externalData]);

  const menusWithActive = menus.map(menu => {
    if (menu.children) {
      const isActive = pathname.startsWith(menu.to);
      const childItems = menu.children.map(child => ({
        ...child,
        isActive: pathname.startsWith(child.to),
        to: getUrl(pathname, child.to, currentParams)
      }));
      return {
        ...menu,
        children: childItems,
        isActive: isActive,
        to: getUrl(pathname, menu.to, currentParams)
      };
    } else {
      return {
        ...menu,
        isActive: pathname.startsWith(menu.to),
        to: getUrl(pathname, menu.to, currentParams)
      };
    }
  });

  return menusWithActive;
};

export default useMenus;

export const getMenus = (id, hasDiversity) => ([
  {
    title: "Guide",
    icon: <ArticleOutlinedIcon />,
    to: `/dashboard/${id}/location-insights/overview`
  },
  {
    title: "Office Location Summary",
    icon: <ArticleOutlinedIcon />,
    to: `/dashboard/${id}/location-insights/hub-summary`
  },
  {
    title: "Internal",
    icon: <InputOutlinedIcon sx={{ width: "20px", ml: "2px" }} />,
    to: `/dashboard/${id}/location-insights/internal-view`,

    children: [
      {
        title: "Summary",
        to: `/dashboard/${id}/location-insights/internal-view/overview`
      },
      {
        title: "Headcount",
        to: `/dashboard/${id}/location-insights/internal-view/headcount`
      },
      {
        title: "Attrition",
        to: `/dashboard/${id}/location-insights/internal-view/attrition`
      },
      {
        title: "Timezone",
        to: `/dashboard/${id}/location-insights/internal-view/timezone`
      },
      {
        title: "Leadership Depth",
        to: `/dashboard/${id}/location-insights/internal-view/leadership-depth`
      },
      {
        title: "Open and Budgeted roles",
        to: `/dashboard/${id}/location-insights/internal-view/recruitment`
      }
    ]
  },
  {
    title: "External market",
    icon: <OutputOutlinedIcon />,
    to: `/dashboard/${id}/location-insights/external-view`,
    children: [
      {
        title: "Summary",
        to: `/dashboard/${id}/location-insights/external-view/overview`
      },
      {
        title: "Talent Supply and Demand",
        to: `/dashboard/${id}/location-insights/external-view/supply-and-demand`
      },
      {
        title: "Hiring Difficulty",
        to: `/dashboard/${id}/location-insights/external-view/hiring-difficulty`
      },
      {
        title: "Talent Competition",
        to: `/dashboard/${id}/location-insights/external-view/talent-competition`
      },
      ...(hasDiversity ? [{
        title: "Market Workforce Diversity",
        to: `/dashboard/${id}/location-insights/external-view/diversity`
      }] : []),
      {
        title: "Role Analysis",
        to: `/dashboard/${id}/location-insights/external-view/role-analysis`
      },
      {
        title: "Relative Compensation Cost",
        to: `/dashboard/${id}/location-insights/external-view/cost-of-labor`
      }
    ]
  },
  {
    title: "FAQs",
    icon: <HelpOutlineOutlinedIcon />,
    to: `/dashboard/${id}/location-insights/faqs`
  },
]) ;

