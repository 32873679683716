
import React from "react";

import SingleLineChartCard from "pages/LocationInsights/components/charts/SingleLineChartCard";
import { useParams, useSearchParams } from "react-router-dom";

const AttritionOverview = ({ 
  data, 
  // classificationData 
}) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();

  const chartDataObject = data?.value?.voluntary;
  const chartData = chartDataObject ? Object.entries(chartDataObject).map(([category, value]) => ({ category, value: Math.round(+value * 10) / 10 })).sort((a, b) => a.category.localeCompare(b.category)) : [];

  return (
    <SingleLineChartCard
      header="Voluntary Attrition"
      subheader="Annualised"
      data={chartData}
      color="#FA4D56"
      url={`/dashboard/${id}/location-insights/internal-view/attrition?${currentParams.toString()}`}
    />
  );
};

export default AttritionOverview;
