import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import MoreInformationPopover from "components/MoreInformationPopover/MoreInformationPopover";
import HeaderCard from "pages/LocationInsights/components/HeaderCard";
import { StatCard } from "pages/LocationInsights/components/StatCard";
import { useOutletContext } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import HiringDifficultyStatus from "./HiringDifficultyStatus";
import OtherLocations from "./OtherLocations";
import OtherLocationsHiringDifficulty from "./OtherLocationsHiringDifficulty";
import PostingDuration from "./PostingDuration";
import helpText from "../helpText";

const HiringDifficultyDetail = () => {
  const { externalData, isLoading } = useOutletContext();

  if(isLoading) {
    return (
      <Grid item md={12}>
        <Box className="flex items-center justify-center w-full" sx={{ height: "calc(100vh - 400px)" }}>
          <BeatLoader color="#5C5470" />
        </Box>
      </Grid>
    );
  }
  
  if(!externalData || externalData.length !== 1) {
    return (
      <Box className="w-full" sx={{ height: "calc(100vh - 400px)" }}>
        <div className="w-3/4 mx-auto">
          <Typography mt={4} variant="body1" textAlign="center" fontWeight="bold" fontSize="24px">
            No data available. Try selecting or changing the location and job title filters
          </Typography>
        </div>
      </Box>
    );
  }

  const data = externalData[0];
  const hireDifficultyData = data.hiringDifficulty && data.score_hiringDifficulty ? { value: data.hiringDifficulty, score: data.score_hiringDifficulty } : null;

  return (
    <div>
      <HeaderCard
        question="How difficult is it to hire in this location, and how long does it typically take?"
        info={<div>
          <p className="mb-1"><b>Hiring difficulty</b> represents how difficult TalentNeuron expects it will be to fill the filtered role at the location</p>
          <MoreInformationPopover helpText={helpText.HiringDifficulty}/>
        </div>}
      />
      <Grid container mt={4} columnSpacing={4}>
        <Grid item lg={4}>
          <div className="flex flex-col gap-6 w-full">
            <HiringDifficultyStatus data={hireDifficultyData} />
            <StatCard 
              mainStat={data.postingDuration}
              mainStatLabel={{ title: "Typical posting duration" }}
              /* subStats={[{
                value: "Talent Nueron",
                icon: <Language sx={{ fontSize: "14px" }} />
              }]} */
            />
            <PostingDuration data={data?.details_postingDuration} />
          </div>
        </Grid>
        <Grid item lg={4}>
          <OtherLocations data={data?.details_location_ranking} />
        </Grid>
        <Grid item lg={4}>
          <OtherLocationsHiringDifficulty data={data?.otherloc_hiringDifficulty} />
        </Grid>
      </Grid>
    </div>
  );
};

export default HiringDifficultyDetail;
