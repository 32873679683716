import React from "react";

import AutodeskImg from "img/favicon-dark.svg" ;
import { NoDataCard } from "pages/LocationInsights/components/NoDataCard";
import { StatCard } from "pages/LocationInsights/components/StatCard";
import { isObjectEmpty } from "utils/object";

const InternalCostOfLabor = ({ data }) => {

  if (!data || (Array.isArray(data) && data.length === 0) || isObjectEmpty(data)) {
    return <NoDataCard title="Relative compensation - Autodesk" />;
  }
    
  const { value, score } = data;
    
  let level = "critical";
  if(score > 2 && score < 5) {
    level = "low";
  } else if(score > 4 && score < 7) {
    level = "medium";
  } else if (score >= 7 ) {
    level = "high";
  }
  return (
    <StatCard
      theme="dark"
      mainStat={value}
      mainStatLabel={{ title: "Relative compensation - Autodesk" }}
      level={level}
      levelConfig={{ inverse: true, solid: true }}
      subStats={[{
        title: `${score}/10`,
        value: "Autodesk",
        icon: <img src={AutodeskImg} alt="Autodesk" className="aspect-square h-3 w-3" />
      }]}
    />
  );
  
};

export default InternalCostOfLabor;
