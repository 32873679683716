export const columns = [
  { 
    key: "geo",
    label: "Geo",
    isSortable: true,
  },
  { 
    key: "country",
    label: "Country",
    isSortable: true,
  },
  { 
    key: "city", 
    label: "City", 
    isSortable: true,
  },
  { 
    key: "team", 
    label: "Team",
    isSortable: true,
  },
  {
    key: "Total Workers",
    label: "Total Workers",
    isSortable: true,
  },
  {
    key: "Total Employees",
    label: "Total Employees",
    color: "#33B1FF",
    isSortable: true,
  },
  {
    key: "Regular Employees",
    label: "Regular Employees",
    color: "#BAE6FF",
    isSortable: true,
  },
  {
    key: "Interns & Co-ops",
    label: "Interns & Co-ops",
    color: "#BAE6FF",
    isSortable: true,
  },
  {
    key: "Fixed Term Employees",
    label: "Fixed Term Employees",
    color: "#BAE6FF",
    isSortable: true,
  },
  {
    key: "Total Contingents (excl. OSP and Assoc)",
    label: "Total Cont.",
    color: "#6FDC8C",
    isSortable: true,
  },
  {
    key: "Agency Temps",
    label: "Agency Temps",
    color: "#ABFFC2",
    isSortable: true,
  },
  {
    key: "Independent Contractors",
    label: "Independent Contractors",
    color: "#ABFFC2",
    isSortable: true,
  },
  {
    key: "Outside Service Providers",
    label: "OSP",
    isSortable: true,
  },
  {
    key: "Associates",
    label: "Associates",
    isSortable: true,
  }
];
