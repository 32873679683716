import React from "react";

import HorizontalChartCard from "pages/LocationInsights/components/charts/HorizontalChartCard";

const NAME_MAPPING = {
  dir_avg_tenure: "Directors",
  snr_dir_avg_tenure: "Senior Directors",
  vp_avg_tenure: "VPs",
  svp_plus_avg_tenure: "SVP and above"
};

const COLOR_MAP = {
  team: "#007D79",
  location: "#08BDBA",
};

const AverageTenure = ({ team = {}, location = {} }) => {
  if(!team && !location) {
    return; 
  }

  //checks to prevent undefined property error
  const chartData = Object.entries(NAME_MAPPING).map(([key, value]) => {
    const data = [];
    data.push({
      label: `${value} (Autodesk)`,
      value: team[key] !== undefined ? +team[key] : 0,
      color: COLOR_MAP.team,
    });
    data.push({
      label: `${value} (Team)`,
      value: location[key] !== undefined ? +location[key] : 0,
      color: COLOR_MAP.location,
    });
    return {
      group: value,
      data
    };
  });

  return (
    <HorizontalChartCard header="Average tenure in years" dataGroups={chartData}/>
  );
};

export default AverageTenure;
