import React, { useEffect } from "react";

import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useLazyGetLocationInsightsQuery } from "store/slices/locationSlice";

import TeamDistributionChart from "./TeamDistributionChart";
import Question from "../../../../components/Question";

const TeamDistributionDetail = () => {
  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetLocationInsightsQuery();
  const { data, isLoading } = useSelector((state) => state.location);

  useEffect(() => {
    const params = Object.fromEntries(currentParams.entries());
    getLocationDetail({ params });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);

  const teamDistribution = data?.metrics?.find((item) => item.metric_name === "team_distribution_breakdown");

  if(isLoading) {
    return (
      <Box className="flex items-center justify-center w-full">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  return (
    <Box>
      <Question text="Which teams have a presence at this location?"></Question>
      {teamDistribution?.value && (
        <TeamDistributionChart data={teamDistribution.value} />
      )}
    </Box>
  );
};

export default TeamDistributionDetail;
