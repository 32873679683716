import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";

import useMenus from "../../useMenus";

const InternalNav = () => {
  const items = useMenus();
  const navigate = useNavigate();
  const activeMenu = items.find(item => item.isActive);
  const activeChild = activeMenu?.children ? activeMenu.children.find(child => child.isActive) : null;
  return activeMenu?.children?.length > 0 ? <ToggleButtonGroup value={activeChild?.to || activeMenu?.to}>
    {
      activeMenu.children.map(child => {
        return <ToggleButton key={child.title} size="small" value={child.to} onClick={() => navigate(child.to)}>
          {child.title}
        </ToggleButton>;
      })
    }
  </ToggleButtonGroup> : null;
};

export default InternalNav;
