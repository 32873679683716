import React from "react";

import { StatCard } from "pages/LocationInsights/components/StatCard";

const CompetitiveConcentration = ({ data }) => {
  return (
    <StatCard mainStat={data} mainStatLabel={{ title: "Competitive concentration" }} level={"neutral"} levelConfig={{ solid: true }}/>
  );
};

export default CompetitiveConcentration;
