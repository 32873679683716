import React, { useEffect, useRef, useState } from "react";

import { Visibility } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import generativeAi from "img/generative-ai.svg";
import { useSearchParams } from "react-router-dom";
import { useGenerateCommentsMutation, useGetCommentFeedbackQuery, useGetCommentsByPageMutation } from "store/slices/commentSlice";
import { useDebounceCallback } from "usehooks-ts";
import { publish } from "utils/events";

import { CommentDrawerWrapper } from "./CommentList/CommentDrawerWrapper";
import { CommentThemeCard } from "./CommentList/CommentThemeCard";
import DownloadExcel from "../components/DownloadExcel";
import { Error } from "../components/Error";
import { Loader } from "../components/Loader";
import { COMMENTS_APP_DATA_LOADING_EVENT } from "../constants";
import { getCommentsDownloadData, getValues } from "../utils";

const CommentsSummary = () => {
  const [generateComments] = useGenerateCommentsMutation();
  const [getComments] = useGetCommentsByPageMutation();
  const [currentParams] = useSearchParams();
  const [isServerError, setIsServerError] = useState(false); 
  const { data: favCom } = useGetCommentFeedbackQuery();
  const favouriteComments = favCom ? JSON.parse(favCom).map(comment => {
    return comment.comment_id;
  }) : [];

  const [themes, setThemes] = useState({ Themes: {} });
  const [comments, setComments] = useState([]);
  const [isCommentsLoading, setIsCommentsLoading] = useState(false);
  const [isThemesLoading, setIsThemesLoading] = useState(false);
  
  const timerRef = useRef(null);

  const country = currentParams.get("country");
  const favorability = currentParams.get("favorability");
  const division = currentParams.get("division");
  const l2m = currentParams.get("l2m");
  const l3m = currentParams.get("l3m");
  const geo = currentParams.get("geo");
  const mgmtLvl = currentParams.get("mgmtLvl");
  const dates = currentParams.get("dates");
  const searchQuery = currentParams.get("search");
  const question = currentParams.get("question");

  const handleGetComments = useDebounceCallback(async (last_evaluated_key, accumulatedComments = []) => {
    try {
      setComments([]);
      setIsCommentsLoading(true);
      const response = await getComments({
        dates: getValues(dates),
        favorability: getValues(favorability),
        question: getValues(question),
        geo: getValues(geo),
        country: getValues(country),
        division: getValues(division),
        mgmtLvl: getValues(mgmtLvl),
        l2m: getValues(l2m),
        l3m: getValues(l3m),
        ...(searchQuery ? { text: searchQuery.toLowerCase() } : {}),
        ...(last_evaluated_key ? { last_evaluated_key: last_evaluated_key } : {}),
      }).unwrap();
  
      if (typeof response === "string") {
        if (!timerRef.current) {
          timerRef.current = setInterval(() => {
            handleGetComments();
          }, 5000);
        }
      } else {
        // If last_evaluated_key is present then we need to fetch more comments
        if (response.last_evaluated_key) {
          const newAccumulatedComments = [...accumulatedComments, ...response.data];
          handleGetComments(response.last_evaluated_key, newAccumulatedComments);
        } else {
          setComments(accumulatedComments.concat(response.data));
          setIsCommentsLoading(false);
          publish(`${COMMENTS_APP_DATA_LOADING_EVENT}:End`);
          if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
          }
        }
      }
    } catch(err) {
      setComments([]);
      setIsCommentsLoading(false);
      publish(`${COMMENTS_APP_DATA_LOADING_EVENT}:End`);
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    }
    
  }, 1000);

  const handleGenerateCommentSummary = useDebounceCallback(async () => {
    try {
      setIsThemesLoading(true);
      const themes = await generateComments({
        dates: getValues(dates),
        favorability: getValues(favorability),
        question: getValues(question),
        geo: getValues(geo),
        country: getValues(country),
        division: getValues(division),
        mgmtLvl: getValues(mgmtLvl),
        l2m: getValues(l2m),
        l3m: getValues(l3m),
        ...(searchQuery ? { text: searchQuery.toLowerCase() } : {}),
      }).unwrap();
      setThemes(themes);
      // enqueueSnackbar("Loading comments, please wait, this may take up to 2 minutes ", { variant: "info", preventDuplicate: true });
    } catch (error) {
      setIsServerError(true);
      publish(`${COMMENTS_APP_DATA_LOADING_EVENT}:End`);
    } finally {
      setIsThemesLoading(false);
    }
  }, 1000);

  useEffect(() => {
    if (dates) {
      handleGenerateCommentSummary();
    }
  }, [currentParams.toString()]);

  useEffect(() => {
    if (dates && Object.keys(themes.Themes).length > 0 && !isServerError && !isThemesLoading) {
      handleGetComments();
    }
  }, [isThemesLoading]);

  if (isThemesLoading) {
    return <Loader title="Hold tight! we are working on it" subtitle="Analysing Comments ... " />;
  }

  if (isServerError) {
    return <Error title="Something is not working at this moment" subtitle="Please change filters or refresh this page" />;
  }

  return (
    <>
      { searchQuery ? <h2 className="text-xl font-bold mb-4">Themes for "{searchQuery}"</h2> : null }
      <div className="p-10 bg-[#f9f9f9]">
        <div className="flex items-center mb-6 gap-2">
          <div>
            <div className="flex items-center gap-1">
              <img src={generativeAi} alt="AI Generated Comments" className="w-6 h-6" />
              <span>{Object.keys(themes.Themes).length} themes generated</span>
            </div>
            <span className="text-sm block text-[#6b7280]">
            The below narrative themes are generated using the natural language processing methodology and Autodesk GPT.  As you review, you can use the themes below in decks for stakeholders (either using text in existing slides or taking a screenshot)
            </span>
          </div>
          <div className="ml-auto flex items-center min-w-fit">
            {/* <Button startIcon={<Download />} onClick={() => generateThemePPT(themes["Themes"], searchQuery)}>Powerpoint slide</Button> */}
            <DownloadExcel data={getCommentsDownloadData(comments)} label="All comments" />
            <CommentDrawerWrapper comments={comments} component={<Button startIcon={<Visibility />} >View all comments</Button>}/>
          </div>
        </div>
        <Grid container spacing={2} alignItems="stretch">
          {
            Object.entries(themes["Themes"]).map(([title, description]) => {
              const filteredComments = comments.filter(comment => {
                return comment.themes.includes(title);
              });
              return <Grid key={title} item md={6} xs={12} lg={4}>
                <CommentDrawerWrapper
                  allComments={comments}
                  comments={filteredComments}
                  component={
                    <CommentThemeCard 
                      title={title} 
                      description={description} 
                      isCommentsLoading={isCommentsLoading} 
                      comments={filteredComments}
                    />
                  } 
                  favouriteComments={favouriteComments} 
                />
              </Grid>;
            })
          }        
        </Grid>
      </div>
    </>
  );
};

export default React.memo(CommentsSummary);
