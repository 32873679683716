import React, { useEffect, useState } from "react";

import { Box, Button, FormControlLabel, Grid, Switch } from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { BeatLoader } from "react-spinners";
import { useGetTimezoneDistributionMutation } from "store/slices/locationSlice";

import ScenarioLocations from "./ScenarioLocations";
import TimezoneFilters from "./TimeZoneFilters";
import { findOverlappingHours, getGroupedTimezoneDistribution } from "../../../utils";

dayjs.extend(timezone);
dayjs.extend(utc);

const DEFAULT_TIMEZONE = "San Francisco (USA)";
const START_TIME = "2024-11-14 09:00";
const END_TIME = "2024-11-14 18:00";
const DEFAULT_TIMEZONE_OFFSET = "GMT-08:00";
const PRE_WORK_HOURS = "2024-11-14 07:00";
const POST_WORK_HOURS = "2024-11-14 21:00";
const DEFAULT_TIMEZONE_ID = "America/Los_Angeles";

const calculateOffset = (offset) => {
  if (offset === "GMT") {
    return 0;
  }
  const [sign, hours, minutes] = offset.match(/([+-])(\d{2}):(\d{2})/).slice(1);
  return (parseInt(hours) * 60 + parseInt(minutes)) * (sign === "+" ? 1 : -1);
};

const formatOffset = (minutes) => {
  const sign = minutes >= 0 ? "+" : "-";
  const absMinutes = Math.abs(minutes);
  const hours = Math.floor(absMinutes / 60).toString().padStart(2, "0");
  const mins = (absMinutes % 60).toString().padStart(2, "0");
  return `${sign}${hours}:${mins}`;
};

const getGlobalOverlapHours = (data, regular = false) => {
  let globalWorkStart = null;
  let globalWorkEnd = null;

  Object.keys(data).forEach((timezone) => {
    const details = data[timezone];

    const workStart = dayjs(regular ? details.work_hours.start : details.extended_work_hours.pre_work_hours);
    const workEnd = dayjs(regular ? details.work_hours.end : details.extended_work_hours.post_work_hours);

    // Update global pre-work start time
    if (globalWorkStart === null || workStart.isAfter(globalWorkStart)) {
      globalWorkStart = workStart;
    }

    // Update global post-work end time
    if (globalWorkEnd === null || workEnd.isBefore(globalWorkEnd)) {
      globalWorkEnd = workEnd;
    }
  });

  // Check if there's an actual overlap
  if (globalWorkStart && globalWorkStart.isBefore(globalWorkEnd)) {
    return {
      fromDate: globalWorkStart.format("YYYY-MM-DD HH:mm:ss"),
      toDate: globalWorkEnd.format("YYYY-MM-DD HH:mm:ss")
    };
  } else {
    return null; // No overlapping hours
  }
};

const getRelativeOverlapHours = (base, data) => {
  const regaularOverlap = findOverlappingHours([{
    start: base.work_hours.start,
    end: base.work_hours.end
  }, {
    start: data.work_hours.start,
    end: data.work_hours.end
  }]);

  const extendedOverlap = findOverlappingHours([{
    start: base.extended_work_hours.pre_work_hours,
    end: base.extended_work_hours.post_work_hours
  }, {
    start: data.extended_work_hours.pre_work_hours,
    end: data.extended_work_hours.post_work_hours
  }]);

  return {
    regularOverlap: regaularOverlap,
    extendedOverlap: extendedOverlap
  };
};

const TimezoneDistribution = ({ timezoneDistribution, baseTimezoneOptions, scenarioLocations }) => {
  const [filterState, setFilterState] = useState({
    baseTimezone: {
      label: DEFAULT_TIMEZONE,
      value: DEFAULT_TIMEZONE_ID,
    },
    baseLineHours: {
      label: "Extended Hours (7am - 9pm)",
      value: "extended",
    },
    otherHours: {
      label: "Extended Hours (7am - 9pm)",
      value: "extended",
    },
    workerType: {
      label: "All",
      value: "All",
    },
    sort: {
      label: "None",
      value: "",
    },
  });
  const [isGrouped, setIsGrouped] = useState(false);
  const [selectedTimezoneData, setSelectedTimezoneData] = useState(timezoneDistribution);
  const [selectedScenarioLocations, setSelectedScenarioLocations] = useState({
    id: 1,
    metric_name: "scenario_locs",
    value: {}
  });
  const [getTimezoneDistribution] = useGetTimezoneDistributionMutation();
  const [isLoading, setIsLoading] = useState(false);

  const filteredScenarioLocations = Object.keys(scenarioLocations?.value ?? {}).filter(city => !selectedTimezoneData?.value?.[city]).map(city => ({
    label: city,
    isScenario: true,
    ...scenarioLocations?.value?.[city]
  }));

  const baseLineTimeZoneObj = selectedTimezoneData?.value?.[filterState.baseTimezone.label] ?? scenarioLocations?.value?.[filterState.baseTimezone.label] ?? {
    timezone: DEFAULT_TIMEZONE_OFFSET,
    work_hours: {
      start: START_TIME,
      end: END_TIME
    },
    extended_work_hours: {
      pre_work_hours: PRE_WORK_HOURS,
      post_work_hours: POST_WORK_HOURS
    },
  };

  const baseLineTz = {
    ...baseLineTimeZoneObj,
    isBaseLine: true,
    base: filterState.baseTimezone.label,
    offset: "Baseline - 00:00"
  };

  const combinedData = { ...(selectedScenarioLocations?.value ?? {}), ...(selectedTimezoneData?.value ?? {}) };

  const restData = [];
  const dataWithBaseLine = combinedData;
  dataWithBaseLine[filterState.baseTimezone.label] = baseLineTimeZoneObj;
  const regularOverlap = getGlobalOverlapHours(dataWithBaseLine, true);

  Object
    .entries(combinedData)
    .forEach(([city, data]) => {
      const { timezone: offset } = data;

      const baseOffset = calculateOffset(baseLineTz.timezone);
      const currentOffset = calculateOffset(offset);
      const relativeOffset = formatOffset(currentOffset - baseOffset);
      let headcount = Number(data.total_headcount);
      if (filterState.workerType.value === "manager") {
        headcount = Number(data.people_manager_headcount);
      } else if (filterState.workerType.value === "ic") {
        headcount = Number(data.individual_contributor_headcount);
      }
      if (city !== filterState.baseTimezone.label) {
        const { regularOverlap, extendedOverlap } = getRelativeOverlapHours(baseLineTz, data);
        restData.push({
          ...data,
          base: city,
          offset: relativeOffset,
          regularOverlap,
          extendedOverlap,
          headcount
        });
      }
    });

  restData.sort((a, b) => {
    if (filterState.sort.value === "workers") {
      return b.headcount - a.headcount;
    } else if (filterState.sort.value === "regular") {
      return b.regularOverlap - a.regularOverlap;
    } else if (filterState.sort.value === "extended") {
      return b.extendedOverlap - a.extendedOverlap;
    } else {
      return 0;
    }
  });

  const groupedData = isGrouped ? getGroupedTimezoneDistribution(restData) : restData;

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const data = [selectedTimezoneData];
        if (Object.keys(selectedScenarioLocations.value).length > 0) {
          data.push(selectedScenarioLocations);
        }
        const res = await getTimezoneDistribution({ baseTimezone: filterState.baseTimezone.value, data: data });
        if(res?.data) {
          setSelectedTimezoneData(res.data.find(item => item.metric_name === "tz_distribution"));
          setSelectedScenarioLocations(res.data.find(item => item.metric_name === "scenario_locs") ?? selectedScenarioLocations);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
  
    if (filterState.baseTimezone.value !== DEFAULT_TIMEZONE_ID) {
      getData();
    } else {
      setSelectedTimezoneData(timezoneDistribution);
    }
  }, [filterState.baseTimezone.value]);

  return (
    <>
      <div className="flex flex-col gap-6 mb-6">
        <p className="text-xl font-extrabold">Timezone analysis</p>
        <TimezoneFilters baseTimezoneOptions={baseTimezoneOptions} filterState={filterState} setFilterState={setFilterState} />
        <div className="flex items-center gap-2">
          <ScenarioLocations
            scenarioLocations={filteredScenarioLocations}
            baseTimezone={filterState.baseTimezone}
            onApply={(selected) => setSelectedScenarioLocations(selected)} 
          />
          
          <div className="ml-2">
            <FormControlLabel control={<Switch value={isGrouped} color="success" onChange={(e) => {
              setIsGrouped(e.target.checked);
            }}/>} label="Group cities with same timezones" />
          </div>
          
          <div className={clsx("flex items-center gap-1 ml-auto")}>
            <div className="h-3 w-3 rounded-full bg-[#78A9FF]"/>
            <p className="">Regular hours (9am - 6pm)</p>
          </div>
          <div className={clsx("flex items-center gap-1")}>
            <div className="h-3 w-3 rounded-full bg-[#D0E2FF]"/>
            <p className="">Extended hours (7am - 9pm)</p>
          </div>
          <div className={clsx("flex items-center gap-1")}>
            <div className="h-3 w-3 rounded-full bg-[#FF832B]"/>
            <p className="">Common hours</p>
          </div>
          <div className={clsx("flex items-center gap-1")}>
            <div className="h-3 w-3 rounded-full bg-[#EE538B]"/>
            <p className="">Scenario city</p>
          </div>
        </div>
      </div>
      <>
        {isLoading ? (
          <Box display="flex" height="400px" alignItems="center" justifyContent="center">
            <BeatLoader color="#5C5470" />
          </Box>
        ) : (
          <>
            {/* <TimezoneContainer data={[baseLineTz]} className="py-6 border-y border-[#e5e5e5]" baseLine regularOverlap={regularOverlap} showHours={filterState.baseLineHours.value} /> */}
            <TimezoneContainer data={[baseLineTz].concat(groupedData)} regularOverlap={regularOverlap} showHours={filterState.otherHours.value} />
          </>
        )}
      </>
    </>
  );
};

const TimezoneContainer = ({ data, className, baseLine, regularOverlap, showHours }) => {
  const hourBars = Array(24).fill(0).map((_, index) => index);
  return <Grid container className={className}>
    <Grid item md={5}>
      {!baseLine ? <div>
        <div className="flex items-center">
          {
            hourBars.slice(0, 9).map((hour, idx) => {
              return <div key={hour} className="flex-1 text-xs font-bold text-black/60 text-right mr-1">{
                hour === 5 ? "6 AM" : ""
              }</div>;
            }
            )
          }
        </div>
        <p className="mb-4 text-sm font-bold text-black/60">Workers - City</p>
      </div> : null}
      <div className="flex flex-col gap-10">
        {
          data.map(item => {
            return <TimezoneScale key={`${item.base}-0-9`} data={item} scaleStart={0} scaleEnd={9} regularOverlap={regularOverlap} showHours={showHours} />;
          })
        }
      </div>
    </Grid>
    <Grid item md={5} className={!baseLine ? "bg-[#F6F2FF] border-x-2 border-dashed border-[#C3C3C3]" : ""}>
      {!baseLine ? <div>
        <div className="flex items-center">
          {
            hourBars.slice(9, 18).map((hour, idx) => {
              return <div key={hour} className="flex-1 text-xs font-bold text-black/60 text-right mr-1">{
                hour === 11 && "12 PM" || hour === 17 && "6 PM"
              }</div>;
            }
            )
          }
        </div>
        <p className="mb-4 text-sm font-bold text-black/60 invisible">A</p>
      </div> : null}
      <div className="flex flex-col gap-10">
        {
          data.map(item => {
            return <TimezoneScale key={`${item.base}-9-18`} data={item} scaleStart={9} scaleEnd={18} hideLabel regularOverlap={regularOverlap} showHours={showHours} />;
          })
        }
      </div>
    </Grid>
    <Grid item md={2}>
      {!baseLine ? <div>
        <div className="flex items-center">
          {
            hourBars.slice(18, 24).map((hour, idx) => {
              return <div key={hour} className="flex-1 text-xs font-bold text-black/60 text-right mr-1">{
                hour === 20 ? "9 PM" : ""
              }</div>;
            }
            )
          }
        </div>
        <p className="mb-4 text-sm font-bold text-black/60 text-right">Overlap Hours</p>
      </div> : null}
      <div className="flex flex-col gap-10">
        {
          data.map(item => {
            return <TimezoneScale key={`${item.base}-18-24`} data={item} scaleStart={18} scaleEnd={24} hideLabel showHours={showHours} showOverlap />;
          })
        }
      </div>
    </Grid>
  </Grid>;
};

const TimezoneScale = ({ data, scaleStart, scaleEnd, hideLabel, className, regularOverlap, showHours = "extended", showOverlap = false }) => {
  const regularTime = data.work_hours ?? {};
  const extendedTime = data.extended_work_hours ?? {};
  const timezone = data.base;
  const offset = data.offset;

  const hourBars = Array(24).fill(0).map((_, index) => index);
  const regularStart = dayjs(regularTime.start).hour();
  const regularEnd = dayjs(regularTime.end).hour();
  const extendedPost = dayjs(extendedTime.post_work_hours).hour();
  const extendedPre = dayjs(extendedTime.pre_work_hours).hour();
  const regularOverlapStart = regularOverlap?.fromDate ? dayjs(regularOverlap.fromDate).hour() : null;
  const regularOverlapEnd = regularOverlap?.toDate ? dayjs(regularOverlap.toDate).hour() : null;
  const hideExtended = showHours === "regular";
  const hasHeadcount = data.headcount > 0;

  return <div className={className}>
    <div className={clsx("mb-2 flex items-center line-clamp-1")}>
      <p className={clsx("p-2 rounded bg-[#f9f9f9] font-medium", { "invisible w-0": hideLabel || !hasHeadcount, "mr-2": hasHeadcount })}>{hasHeadcount ? data.headcount : "0"}</p>
      <p className={clsx("text-lg line-clamp-1", { "invisible": hideLabel, "text-[#EE538B]": data.isScenario, "font-semibold": data.isBaseLine })}>{timezone}</p>
      <p className={clsx("text-xs text-black/60 ml-2", { "hidden": hideLabel, "font-bold": data.isBaseLine })}>{offset}</p>
      <div className="flex items-center gap-1 ml-auto">
        {
          showOverlap && data.extendedOverlap > 0 ? <div className="flex gap-1 items-center ml-auto mr-2 p-2 rounded bg-[#f9f9f9]">
            <p className="font-medium mr-1">{data.extendedOverlap}</p>
            <p className="ml-1 pl-1 text-xs font-semibold text-black/40 border-l border-[#e5e5e5]">Total</p>
          </div> : null
        }
        {
          showOverlap && data.regularOverlap > 0 ? <div className="flex gap-1 items-center ml-auto mr-2 p-2 rounded bg-[#f9f9f9]">
            <p className="font-medium mr-1">{data.regularOverlap}</p>
            <p className="ml-1 pl-1 text-xs font-semibold text-black/40 border-l border-[#e5e5e5]">Regular</p>
          </div> : null
        }
      </div>
    </div>
    <div className="flex items-center gap-px">
      {
        hourBars.slice(scaleStart, scaleEnd).map((hour, idx) => {
          let isRegular = regularStart < regularEnd ? (hour >= regularStart && hour < regularEnd) : ( hour >= regularStart || hour < regularEnd);
          const isExtended = !isRegular && (extendedPre > extendedPost ? (hour >= extendedPre || hour < extendedPost) : (hour >= extendedPre && hour < extendedPost));
          const isOverlap = regularOverlapStart !== null && hour >= regularOverlapStart && hour < regularOverlapEnd;
          return <div key={hour} className={clsx("flex-1 h-1", { 
            "bg-[#78A9FF]": isRegular && !isOverlap, 
            "bg-[#D0E2FF]": !hideExtended && isExtended && !isRegular,
            "bg-[#F7F7F7]": (isExtended && hideExtended) || (!isRegular && !isExtended && !isOverlap),
            "bg-[#FF832B]": isOverlap,
          })}></div>;
        })
      }
    </div>
  </div>;
};

export default TimezoneDistribution;
