import React from "react";

import { Grid } from "@mui/material";

import JobTitleFilter from "./JobTitleFilter";
import LocationFilter from "./LocationFilter";

const Filters = ({ filterOptions }) => {
  return (
    <div className="p-3 border border-gray-300 rounded-lg bg-[#f9f9f9]">
      <Grid container>
        <Grid item md={8} className="pr-3 border-r border-gray-300">
          <LocationFilter filterOptions={filterOptions} />
        </Grid>
        <Grid item md={4} className="pl-3">
          <JobTitleFilter filterOptions={filterOptions} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Filters;
