import React, { useState } from "react";

import { DoDisturbOn, ThumbDown, ThumbUp, TurnedIn, TurnedInNot } from "@mui/icons-material";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useAddCommentFeedbackMutation } from "store/slices/commentSlice";

const favorabilityIcons = {
  "Favorable": <ThumbUp className="!fill-green-600" fontSize="small" />,
  "Unfavorable": <ThumbDown className="!fill-red-600" fontSize="small" />,
  "Neutral": <DoDisturbOn className="!fill-gray-200" fontSize="small" />,
};

const Comment = ({ comment, favorite, highlight }) => {
  const [currentParams] = useSearchParams();
  const [like, setLike] = useState(Boolean(favorite));
  const [addComment] = useAddCommentFeedbackMutation();

  const search = currentParams.get("search") || "";

  const handleLike = () => {
    if(!like) {
      setLike(true);
      addComment({
        commentId: comment.comment_id,
        likeType: "liked",
        searchTerm: search,
        comment: comment.comment,
        question: comment.question,
        division: comment.division,
        geo: comment.geo,
        managementLevel: comment.management_level,
        surveyDate: comment.survey_date,
        favorability: comment.favorability,
      });
    } else {
      setLike(false);
      addComment({
        commentId: comment.comment_id,
        likeType: "unliked",
        searchTerm: search,
      });
    }
  };

  const commentHtml = highlight ? comment.comment.replace(new RegExp(highlight, "gi"), match => `<span class="font-semibold bg-yellow-400">${match}</span>`) : comment.comment;

  return (
    <div className="pt-5 pb-3 flex gap-3 border-b border-gray-200 justify-between group cursor-pointer">
      <div className="flex-1">
        <div className="mb-2 flex justify-between items-center w-full">
          <div className="flex items-center gap-1">
            {favorabilityIcons[comment.favorability]}
            <span>{comment.favorability}</span>
          </div>
          <div className="flex items-center gap-1">
            <Typography variant="body2" color="textSecondary">
              {dayjs(comment.survey_date).format("MMM 'YY")}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {comment.question}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {comment.geo} {comment.country}
            </Typography>
          </div>
        </div>
        <p dangerouslySetInnerHTML={{
          __html: commentHtml
        }} />
      </div>
      
      {like ?
        <TurnedIn className="!fill-blue-500 cursor-pointer" onClick={handleLike} /> :
        <TurnedInNot className="cursor-pointer invisible group-hover:visible
        " onClick={handleLike} />
      }
    </div>
  );
};

export default Comment;
