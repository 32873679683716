import React from "react";

import { StatCard } from "pages/LocationInsights/components/StatCard";

const HiringDifficultyStatus = ({ data }) => {
  const { value: label, score } = data;
  let level = "critical";
  if(score > 2 && score < 5) {
    level = "low";
  } else if(score > 4 && score < 7) {
    level = "medium";
  } else if (score >= 7 ) {
    level = "high";
  }
  
  return (
    <StatCard theme="dark" mainStat={label} mainStatLabel={{ title: "Hiring difficulty" }} level={level} levelConfig={{ inverse: true, solid: true }} />
  );
};

export default HiringDifficultyStatus;
