import { Box, Card, CardContent, Typography } from "@mui/material";
import { Handle, Position } from "@xyflow/react";
import { convertToTitleCase } from "utils/string";
 
const DecisionTreeNode = ({ data }) => {
  return (
    <>
      <Handle type="target" style={{ visibility: "hidden" }} position={Position.Top} />
      <Card sx={{ width: "160px" }}>
        <Box sx={{ bgcolor: "#8686e3", color: "white", height: "80px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Typography variant="body2" textAlign="center" fontSize="18px">{convertToTitleCase(data.split)}</Typography>
        </Box>
      </Card>
      <Handle type="source" style={{ visibility: "hidden" }} position={Position.Bottom} id="a" />
    </>
  );
};

const TrustNode = ({ data }) => {
  return (
    <>
      <Handle type="target" style={{ visibility: "hidden" }} position={Position.Top} />
      <Card sx={{ height: "80px", width: "160px", backgroundColor: data.bgColor, color: "white" }}>
        <CardContent sx={{ p: 1.5, display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
          <Box>
            <Typography variant="body1" textAlign="center" fontSize="20px">{Math.round(data.leaf)}</Typography>
            <Typography variant="body1" textAlign="center">(N = {data.count})</Typography>
          </Box>
        </CardContent>
      </Card>
      <Handle type="source" style={{ visibility: "hidden" }} position={Position.Bottom} id="a" />
    </>
  );
};

export { DecisionTreeNode, TrustNode };
