import React, { useLayoutEffect, useState, useRef } from "react";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { getLocationNameFromQueryParams, getTeamFromQueryParams } from "../../../utils";

const COLOR_MAP = {
  "AMER": "#EE538B",
  "APAC": "#1292E8",
  "EMEA": "#6929C4",
};

const OtherLocations = ({ data }) => {
  const [currentParams] = useSearchParams();
  const locationName = getLocationNameFromQueryParams(currentParams);
  const teamName = getTeamFromQueryParams(currentParams);
  const [geo, setGeo] = useState("AMER");

  const geoData = data?.find(item => item.category === geo);
  return (
    <div>
      <div className="flex items-center mb-1">
        <p className="text-xl font-extrabold mr-6">{teamName} Headcounts</p>
        <ToggleButtonGroup size="small">
          {
            Object.keys(COLOR_MAP).map((key) => (
              <ToggleButton key={key} value={key} selected={geo === key} onClick={() => setGeo(key)}>{key}</ToggleButton>
            ))
          }
        </ToggleButtonGroup>
      </div>
      <OtherLocationsChart data={geoData?.breakdown ?? []} geo={geo} />
    </div>
  );
};

export default OtherLocations;

// dynamically assign rotation value - if there's space render horizontally, else at an angle
const OtherLocationsChart = ({ data, geo }) => {
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    let root = am5.Root.new(chartRef.current);
    root._logo.dispose();

    const theme = am5.Theme.new(root);
    theme.rule("ColorSet").setAll({
      colors: [
        am5.color(COLOR_MAP[geo] ?? "#EE538B"),
      ],
      reuse: true
    });

    root.setThemes([am5themes_Animated.new(root), theme]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
        maxTooltipDistance: 0
      })
    );

    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true
    });

    const updateLabelRotation = () => {
      const containerWidth = chartRef.current.offsetWidth;
      const labelWidth = 100; // Approximate width for labels
      const totalLabelsWidth = (data.length * labelWidth);

      if (totalLabelsWidth > containerWidth) {
        xRenderer.labels.template.setAll({
          fill: am5.color("#666666"),
          centerY: am5.p50,
          centerX: am5.p100,
          fontSize: 12,
          rotation: -45,
        });
      } else {
        xRenderer.labels.template.setAll({
          fill: am5.color("#666666"),
          centerY: am5.p50,
          centerX: am5.p50,
          fontSize: 12,
          rotation: 0,        
        });
      }
    };

    updateLabelRotation();
    window.addEventListener("resize", updateLabelRotation);

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "category",
        renderer: xRenderer,
      })
    );
    xAxis.data.setAll(data);

    xRenderer.grid.template.setAll({
      visible: false
    });

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, { xAxis: xAxis }));
    cursor.lineY.set("visible", false);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {}),
        extraTooltipPrecision: 1
      })
    );

    yAxis.get("renderer").labels.template.setAll({
      fill: am5.color("#666666"),
      textAlign: "center",
      fontSize: 12,
    });

    function makeSeries(name) {
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "category",
      }));

      series.columns.template.setAll({
        tooltipText: "{categoryX}: {valueY}",
        maxTooltipDistance: 0,
        cornerRadiusTL: 5,
        cornerRadiusTR: 5,
      });

      series.data.setAll(data);

      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            centerX: am5.p50,
            centerY: am5.p50,
            text: "{valueY}",
            fill: am5.color("#666"),
            fontSize: 12,
            populateText: true
          })
        });
      });

      series.columns.template.onPrivate("height", function(height, target) {
        am5.array.each(target.dataItem.bullets, function(bullet) {
          if (height > 50) {
            bullet.set("locationY", 0.5);
            bullet.get("sprite").set("centerY", am5.p50);
            bullet.get("sprite").set("fill", am5.color("#fff"));
          } else {
            bullet.set("locationY", 1);
            bullet.get("sprite").set("centerY", am5.p100);
          }
        });
      });
    }

    makeSeries(geo);

    return () => {
      root.dispose();
      window.removeEventListener("resize", updateLabelRotation);
    };
  }, [data, geo]);

  return (
    <Box ref={chartRef} id="other-locations" style={{ width: "100%", height: "600px" }}></Box>
  );
};
