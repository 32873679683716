import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import MoreInformationPopover from "components/MoreInformationPopover/MoreInformationPopover";
import PieChartCard from "pages/LocationInsights/components/charts/PieChartCard";
import HeaderCard from "pages/LocationInsights/components/HeaderCard";
import { getPercentageString } from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/LocationInsightsInternalDetail/utils";
import { useOutletContext } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { convertToTitleCase } from "utils/string";

import helpText from "../helpText";

const genderColors = {
  "men": "#1292E8",
  "women": "#EE538B",
};

const ethicColors = {
  "asian": "#B28600",
  "black": "#570408",
  "latino": "#198038",
  "white": "#BFBFBF",
  "other": "#A56EFF",
};

const DiversityDetail = () => {
  const { externalData, isLoading } = useOutletContext();
  if(isLoading) {
    return (
      <Grid item md={12}>
        <Box className="flex items-center justify-center w-full" sx={{ height: "calc(100vh - 400px)" }}>
          <BeatLoader color="#5C5470" />
        </Box>
      </Grid>
    );
  }
  
  if(!externalData || externalData.length !== 1) {
    return (
      <Box className="w-full" sx={{ height: "calc(100vh - 400px)" }}>
        <div className="w-3/4 mx-auto">
          <Typography mt={4} variant="body1" textAlign="center" fontWeight="bold" fontSize="24px">
            No data available. Try selecting or changing the location and job title filters
          </Typography>
        </div>
      </Box>
    );
  }

  const genderDiversityData = externalData[0].details_genderDiversity ? Object.entries(externalData[0].details_genderDiversity).reduce((acc, [key, value]) => ({ ...acc, [key]: +value }), {}) : null;
  const ethicDiversityData = externalData[0].details_ethnicityDiversity ? Object.entries(externalData[0].details_ethnicityDiversity).reduce((acc, [key, value]) => ({ ...acc, [key]: +value }), {}) : null;

  const totalGenderValues = Object.values(genderDiversityData || {}).reduce((acc, value) => acc + value, 0);
  const totalEthnicValues = Object.values(ethicDiversityData || {}).reduce((acc, value) => acc + value, 0);

  const genderChartData = genderDiversityData 
    ? Object.entries(genderDiversityData)
      .map(([key, value]) => ({ 
        label: convertToTitleCase(key), 
        value, 
        additionalValue: getPercentageString(value, totalGenderValues),
        color: genderColors[key] 
      })) 
    : [];

  const ethicChartData = ethicDiversityData 
    ? Object.entries(ethicDiversityData)
      .map(([key, value]) => ({ 
        label: convertToTitleCase(key),
        value,
        additionalValue: getPercentageString(value, totalEthnicValues),
        color: ethicColors[key]
      })) 
    : [];

  return (
    <div>
      <HeaderCard 
        question="What is the diversity of talent in this location?"
        info={<MoreInformationPopover helpText={helpText.diversity} />}
      />
      <Grid container mt={4} columnSpacing={4}>
        <Grid item lg={4}>
          <PieChartCard header="Gender diversity" data={genderChartData} />
        </Grid>
        <Grid item lg={4}>
          <PieChartCard header="Ethnicity diversity" data={ethicChartData} />
        </Grid>
      </Grid>
    </div>
  );
};

export default DiversityDetail;
