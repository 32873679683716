export const columns = [
  { 
    key: "geo",
    label: "Geo",
  },
  { 
    key: "country",
    label: "Country",
  },
  { 
    key: "city", 
    label: "City", 
  },
  {
    key: "rel_cost_labor",
    label: "Relative Compensation Cost",
  },
  {
    key: "supply",
    label: "Talent Supply",
    muiTableBodyCellProps: {
      align: "right",
    },
  },
  {
    key: "demand",
    label: "Talent Demand",
    muiTableBodyCellProps: {
      align: "right",
    },
  },
  {
    key: "supplyDemandRatio",
    label: "Supply/Demand Ratio",
  },
  {
    key: "competitiveConcentration",
    label: "Competitive Concentration",
  },
  {
    key: "postingDuration",
    label: "Typical Posting Duration",
  },
  {
    key: "hiringDifficulty",
    label: "Hiring Difficulty",
  },
  {
    key: "country_corporate_income_taxrate",
    label: "Corporate Income Tax Rate",
    muiTableBodyCellProps: {
      align: "right",
    },
  },
  {
    key: "english_proficiency",
    label: "English Proficiency",
    muiTableBodyCellProps: {
      align: "left",
    },
  }
];
