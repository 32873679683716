import React from "react";

import { Box, Grid } from "@mui/material";
import AutodeskImg from "img/favicon-dark.svg";
import AutodeskImgLight from "img/favicon.png";
import HeaderCard from "pages/LocationInsights/components/HeaderCard";
import { StatCard } from "pages/LocationInsights/components/StatCard";
import { useOutletContext, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import HeadcountFlexElection from "./HeadcountFlexElection";
import HeadcountGeoDistribution from "./HeadcountGeoDistribution";
import HeadcountTypeBreakdown from "./HeadcountTypeBreakdown";
import OtherLocations from "./OtherLocations";

const HeadcountDetail = () => {
  const { data, isLoading } = useOutletContext();
  const { id } = useParams();

  const headCountSummaryData = data?.metrics?.find((item) => item.metric_name === "headcount_summary");
  const headcountTypeBreakdownData = data?.metrics?.find((item) => item.metric_name === "headcount_worker_sub_type");
  const headcountFlexElectionData = data?.metrics?.find((item) => item.metric_name === "headcount_flex_election");
  const autodeskHeadcountData = data?.adsk_location_metrics?.find((item) => item.metric_name === "headcount_summary");
  const otherLocationsData = data?.metrics?.find((item) => item.metric_name === "other_locations_team");

  if(isLoading) {
    return (
      <Grid item md={12}>
        <Box className="flex items-center justify-center w-full" sx={{ height: "calc(100vh - 400px)" }}>
          <BeatLoader color="#5C5470" />
        </Box>
      </Grid>
    );
  }

  return (
    <Box>
      <HeaderCard 
        question="What is the headcount distribution for this and all teams at this location?"
        linkText="Headcount dashboard"
        linkUrl={`/dashboard/${id}/location-insights/tableau?url=https://people-insights-tableau.autodesk.com/views/HeadcountDashboard-New/Summary`}
        info="All headcount numbers exclude workers in the Associates and Outside Service Providers subtypes unless indicated otherwise."
      />

      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={4}>
          <div className="flex flex-col gap-4">
            <StatCard
              mainStat={new Intl.NumberFormat("en-US").format(headCountSummaryData?.value["Total (excl. Assoc & OSP)"] ?? 0)} 
              mainStatLabel={{ 
                title: "Total workers",
                value: "(including employees and contingent workers)",
              }}
              subStats={[{
                value: "Autodesk",
                title: new Intl.NumberFormat("en-US").format(autodeskHeadcountData?.value["Total (excl. Assoc & OSP)"] ?? 0),
                icon: <img src={AutodeskImg} alt="Autodesk" className="aspect-square h-3 w-3" />,
              }]}
              theme="dark"
            />

            <StatCard
              mainStat={new Intl.NumberFormat("en-US").format(headCountSummaryData?.value?.Employees ?? 0)} 
              mainStatLabel={{ 
                title: "Total employees",
                icon: <div className="w-4 h-4 rounded-full" style={{ backgroundColor: "#33B1FF" }}></div>
              }}
              subStats={[{
                value: "Autodesk",
                title: new Intl.NumberFormat("en-US").format(autodeskHeadcountData?.value?.Employees ?? 0),
                icon: <img src={AutodeskImgLight} alt="Autodesk" className="aspect-square h-3 w-3" />,
              }]}
            />

            <StatCard
              mainStat={new Intl.NumberFormat("en-US").format(headCountSummaryData?.value["Contingents (excl. Assoc & OSP)"] ?? 0)} 
              mainStatLabel={{ 
                title: "Total contingents",
                icon: <div className="w-4 h-4 rounded-full" style={{ backgroundColor: "#198038" }}></div>
              }}
              subStats={[{
                value: "Autodesk",
                title: new Intl.NumberFormat("en-US").format(autodeskHeadcountData?.value["Contingents (excl. Assoc & OSP)"] ?? 0),
                icon: <img src={AutodeskImgLight} alt="Autodesk" className="aspect-square h-3 w-3" />,
              }]}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <HeadcountTypeBreakdown data={headcountTypeBreakdownData?.value} />
        </Grid>
        <Grid item xs={12} md={4}>
          <HeadcountFlexElection data={headcountFlexElectionData?.value} />
        </Grid>
      </Grid>

      <div className="mt-4 p-6 rounded-3xl bg-[#f9f9f9] border-[#e5e5e5] border">
        <div className="flex items-center mb-6">
          <p className="text-xl font-extrabold">Geo and country distribution</p>
        </div>
        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={4}>
            <HeadcountGeoDistribution data={otherLocationsData?.value} />
          </Grid>
        </Grid>

        <div className="mt-6 pt-6 border-t border-[#e5e5e5]">
          <OtherLocations data={otherLocationsData?.value ?? []} />
        </div>
      </div>
    </Box>
  );
};

export default HeadcountDetail;
