
import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import JourneyCard from "components/LocationInsights/JourneyCard";
import { useOutletContext } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import ExternalViewTable from "./ExternalViewTable/ExternalViewTable";
import { CompetitiveConcentration, CorporateIncomeTaxRate, EnglishProficiency, HiringDifficulty, Supply, SupplyDemandRatio } from "./LocationInsightsExternalDetail/Overview/Overview";

const LocationInsightsExternalView = () => {
  const { externalData, isLoading } = useOutletContext();

  return (
    <Box px={4} py={3}>
      <Box sx={{ minHeight: "calc(100vh - 400px)" }}>
        <Grid container mt={4} columnSpacing={4}>
          {isLoading ? (
            <Grid item md={12}>
              <Box className="flex items-center justify-center w-full" sx={{ height: "calc(100vh - 400px)" }}>
                <BeatLoader color="#5C5470" />
              </Box>
            </Grid>
          ) : (
            externalData?.length === 1 ? (
              <>
                <Grid item xs={12} lg={4}>
                  <div className="flex flex-col gap-6 w-full">
                    <Supply data={externalData[0].supply} />
                    <SupplyDemandRatio data={externalData[0].supplyDemandRatio} />
                  </div>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <div className="flex flex-col gap-6 w-full">
                    <HiringDifficulty data={externalData[0].hiringDifficulty} />
                    <CompetitiveConcentration data={externalData[0].competitiveConcentration} />
                  </div>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <div className="flex flex-col gap-6 w-full">
                    <CorporateIncomeTaxRate data={externalData[0].country_corporate_income_taxrate} />
                    <EnglishProficiency data={externalData[0].english_proficiency} />
                  </div>
                </Grid>
                <Grid item xs={12} mt={4}>
                  <ExternalViewTable data={externalData[0].data_table} />
                </Grid>
              </>
            ) : (
              <Grid item md={12}>
                <div className="w-3/4 mx-auto">
                  <Typography mt={4} variant="body1" textAlign="center" fontWeight="bold" fontSize="24px">
                    No data available. Try selecting or changing the location and job title filters
                  </Typography>
                </div>
              </Grid>
            )
          )}
          <Grid item xs={12}>
            <JourneyCard />
          </Grid>   
        </Grid>
      </Box>
    </Box>
  );
};

export default LocationInsightsExternalView;
