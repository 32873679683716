import React from "react";

import { Autocomplete, FormControl, lighten, styled, TextField, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const JobTitleFilter = ({ filterOptions }) => {
  const [currentParams, setCurrentParams] = useSearchParams();
  
  const job = currentParams.get("job_title");
  const jobOptions = filterOptions?.job_code || {};
  const selectedJob = { label: job || "", value: jobOptions[job]?.code || "", group: jobOptions[job]?.group || "" };
  const selectMenus = Object
    .entries(jobOptions).map(([label, value]) => ({ label, value: value?.code, group: value?.group }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const groups = [...new Set(selectMenus.map((item) => item.group))];

  console.log(groups );

  /* useEffect(() => {
    if(selectMenus.length > 0 && !job) {
      currentParams.set("job_code", selectMenus[0].value);
      currentParams.set("job_title", selectMenus[0].label);
      setCurrentParams(currentParams);
    }
  }, [selectMenus, job]); */

  const handleChangeJob = (value) => {
    if(!value?.value) {
      currentParams.delete("job_code");
      currentParams.delete("job_title");
    } else {
      currentParams.set("job_code", value.value);
      currentParams.set("job_title", value.label);
    }
    setCurrentParams(currentParams);
  };

  return (
    <>
      <Typography variant="body1" gutterBottom>Job Title</Typography>
      <FormControl size="small" sx={{ width :"100%", backgroundColor: "white" }}>
        <Autocomplete
          value={selectedJob}
          onChange={(_e, values)=>handleChangeJob(values)}
          groupBy={(option) => option.group}
          size="small"
          ChipProps={{
            variant: "contained",
            color: "primary",
          }}
          getOptionLabel={(option) => option.label}
          options={selectMenus.sort((a, b) => a.group.localeCompare(b.group)) || []}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Job Title"
            />
          )}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
        />
      </FormControl>
    </>
  );
};

export default JobTitleFilter;
