import React, { useEffect } from "react";

import { Box } from "@mui/material";
import JourneyCard from "components/LocationInsights/JourneyCard";
import { useSelector } from "react-redux";
import { Outlet, useSearchParams } from "react-router-dom";
import { useGetLocationFiltersQuery, useLazyGetExternalLocationInsightsQuery } from "store/slices/locationSlice";

import Filters from "./Filters/Filters";
import InternalNav from "../LocationInsightsInternalView/Nav/InternalNav";

const ExternalViewDetailLayout = () => {

  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetExternalLocationInsightsQuery();
  const { externalData, isLoading } = useSelector((state) => state.location);

  const { data: filterData } = useGetLocationFiltersQuery();
  const filterOptions = filterData?.["location-insights-external"] || [];
  
  useEffect(() => {
    const params = Object.fromEntries(currentParams.entries());
    if(params.geo && params.country && params.city && params.job_code) {
      getLocationDetail({ params });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams.toString()]);

  return (
    <Box px={4} py={3}>
      <Filters filterOptions={filterOptions} />
      
      <div className="mt-4">
        <InternalNav />
      </div>

      <div className="mt-4 px-4 py-2">
        <Outlet context={{ externalData, isLoading, filterOptions }}/>
      </div>
      
      <JourneyCard />
    </Box>
  );
};

export default ExternalViewDetailLayout;
