
import { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Box, Typography } from "@mui/material";
import { ColorTheme1 } from "theme/chart";

const DemandTrend = ({ data }) => {
  const chartData = data ? data.map((item) => ({
    period: item.period,
    demandCount: +item.demandCount
  })) : null;

  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" my={4}>
        Demand Trend
      </Typography>
      <DemandTrendChart
        id="demand-trend"
        data={chartData}
        dataKeys={[
          {
            name: "Demand Count",
            yKey: "demandCount"
          }
        ]}
        categoryKey="period"
      />
    </Box>
  );
};

export default DemandTrend;

const DemandTrendChart = ({ data, dataKeys=[], id, height="400px", categoryKey="category", unit }) => {

  // This code will only run one time
  useLayoutEffect(() => {
    const root = am5.Root.new(id);

    root.setThemes([ColorTheme1.new(root)]);
    root._logo.dispose();

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout
      })
    );

    const yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.labels.template.setAll({
      fill: am5.color("#666666"),
      textAlign: "center",
      fontSize: 12,
    });
        
    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: yRenderer,
        numberFormat: unit ? `# '${unit}'`: "#",
        extraMax: 0.05
      })
    );

    let xRenderer = am5xy.AxisRendererX.new(root, { 
      minorGridEnabled: true,
      minGridDistance: 60 
    });
    xRenderer.labels.template.setAll({
      fill: am5.color("#666666"),
      textAlign: "center",
      fontSize: 12,
      text: "{category.formatDate('MMM yy')}"
    });
    xRenderer.grid.template.set("visible", false);

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: categoryKey,
      })
    );

    xAxis.data.setAll(data);

    function makeSeries(name, yValueField, xCategoryField, countKey) {
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name || "Series",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: yValueField,
          categoryXField: xCategoryField,
          stroke: am5.color("#005D5D"),
          tooltip: am5.Tooltip.new(root, {
            labelText: 
              countKey ? 
                unit ? `{name}: {valueY}${unit}\nCount: {${countKey}} \n Period: {categoryX.formatDate('MMMM yyyy')}`
                  : `{name}: {valueY}\nCount: {${countKey}} \n Period: {categoryX.formatDate('MMMM yyyy')}`
                :unit ? `{name}: {valueY}${unit} \n Period: {categoryX.formatDate('MMMM yyyy')}`
                  : "{name}: {valueY} \n Period: {categoryX.formatDate('MMM yyyy')}",
          }),
        })
      );

      series.strokes.template.setAll({
        strokeWidth: 4
      });

      series.data.setAll(data);
      series.appear();
    }

    dataKeys.forEach(({ name, yKey, countKey }) => {
      makeSeries(name, yKey, categoryKey, countKey);
    });

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id={id} style={{ width: "100%", height }}></div>;
};

