import React, { useState } from "react";

import { Close, TipsAndUpdates } from "@mui/icons-material";
import { Drawer, IconButton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DataTable } from "components/DataTable";
import DownloadExcelButton from "pages/LocationInsights/components/DownloadExcelButton";

const columns = [
  {
    key: "division",
    label: "Division",
    isSortable: true,
  }, {
    key: "Directors",
    label: "Directors",
    isSortable: true,
    color: "#8A3FFC",
  },
  {
    key: "Snr Directors",
    label: "Senior Directors",
    isSortable: true,
    color: "#D4BBFF",
  },
  {
    key: "VPs",
    label: "VPs",
    isSortable: true,
    color: "#D4BBFF",
  },
  {
    key: "SVPs and above",
    label: "SVPs and above",
    isSortable: true,
    color: "#D4BBFF",
  }
];

const tabs = ["All", "Directors", "Senior Directors", "VPs", "SVPs and above"];

const LeadershipDepthCount = ({ locationValue, leaderShipStaff }) => {

  const [open, setOpen] = useState(false);
  const [division, setDivision] = useState(null);
  const [tab, setTab] = useState("All");

  const data = Object.entries(locationValue).map(([key, value]) => {
    return {
      division: key,
      ...value
    };
  });

  const handleDrawerClose = (e, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      setOpen(false);
    }
  };

  const staffDetails = tab !== "All" ? leaderShipStaff[division]?.[tab] ?? [] : Object.values(leaderShipStaff[division] ?? {})?.flat();

  const staffCount = tabs.slice(1).reduce((acc, tab) => {
    acc[tab] = leaderShipStaff[division]?.[tab]?.length ?? 0;
    return acc;
  }, {});

  const totalStaff = Object.values(staffCount).reduce((acc, count) => acc + count, 0);
  
  return (
    <div className="flex flex-col gap-6 w-full">
      <p className="text-xl font-extrabold mr-auto">Division breakdown at this location </p>
      <div className="flex items-center gap-6">
        <p className="font-small left-align mr-auto"> 
          <IconButton><TipsAndUpdates /></IconButton> Click on a division to view its leaders at the selected location</p>
        <DownloadExcelButton data={data} columns={columns} title="Division breakdown" />
      </div>
      
      <DataTable data={data} groups={columns} hasAdditionalInfo onRowClick={(row) => {
        setDivision(row.division);
        setOpen(true);
      }} />
      <Drawer anchor="right" open={open} onClose={handleDrawerClose}>
        <div className="w-[800px] px-8 pb-8" role="presentation">
          <div className="sticky top-0 bg-white z-10 pt-8">
            <div className="flex justify-between items-center pb-4 mb-4 border-b border-[#e5e5e5]">
              <div>
                <h2 className="text-lg font-bold">Leadership staff details</h2>
                <p>{division}</p>
              </div>
              <div>
                <IconButton onClick={() => setOpen(false)}>
                  <Close />
                </IconButton>
              </div>
            </div>
            <ToggleButtonGroup value={tab} exclusive onChange={(e, value) => setTab(value)} size="small" className="mt-4 mb-4">
              {tabs.map((t) => {
                return <ToggleButton key={t} value={t}>{t} - {t === "All" ? totalStaff : staffCount[t]}</ToggleButton>;
              })}
            </ToggleButtonGroup>
          </div>
          <div className="overflow-auto mt-4">
            {staffDetails.map((staff, index) => {
              return <div key={index} className="flex flex-col gap-1 pb-2 mb-2 border-b border-[#e5e5e5]">
                <p className="text-lg">{staff.name}</p>
                <p className="text-base text-black/60">{staff.business_title}</p>
                <p className="text-base text-black/60">{staff.email_address}</p>
              </div>;
            }
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default LeadershipDepthCount;

