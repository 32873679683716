import { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { OpenInNew } from "@mui/icons-material";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { ColorTheme10 } from "theme/chart";

/* interface LineChartCardProps {
  header: string;
  subheader: string;
  data: {
      label: string;
      value: string | number;
      additionalValue: string | number;
    }[];
  color: string;
  className?: string;
} */

const SingleLineChartCard = ({ header, subheader, data = [], color = "#000000", className, url }) => {
  const navigate = useNavigate();
  return (
    <div onClick={() => {
      if (url) {
        navigate(url);
      }
    }} className={clsx("flex flex-col bg-white rounded-3xl p-6 border-[#e5e5e5] border group", { "hover:border-black cursor-pointer": url }, className)}>
      <div className="border-b border-[#e5e5e5] pb-2 mb-2">
        <p className={clsx("font-bold flex items-center gap-1", {
          "group-hover:underline": url,
        })}>
          {header}
          <div className={clsx("hidden", { "group-hover:block": url })}><OpenInNew style={{ fontSize: "16px" }} /></div>
        </p>
        <p className="text-sm text-black/60 line-clamp-1">{subheader}</p>
      </div>
      <div>
        <LineChart id={header} data={data} color={color} />
      </div>
    </div>
  );
};

export default SingleLineChartCard;

const LineChart = ({ id, data, color }) => {
  const chartId = id || "line-chart";
  useLayoutEffect(() => {
    const root = am5.Root.new(chartId);
    root.setThemes([ColorTheme10.new(root)]);
    root._logo.dispose();

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout
      })
    );

    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
    xRenderer.setAll({
      cellStartLocation: 0.2,
      cellEndLocation: 0.8,
    });
    xRenderer.labels.template.setAll({
      fill: am5.color("#666666"),
      textAlign: "center",
      fontSize: 12,
      text: "{category}\n[bold][fontSize: 16px]{value}%",
    });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: xRenderer
      })
    );
    xAxis.data.setAll(data);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {
        }),
      })
    );

    // xAxis.get("renderer").labels.template.set("visible", false);
    yAxis.get("renderer").grid.template.set("visible", false);
    yAxis.get("renderer").labels.template.set("visible", false);

    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "category",
        stroke: am5.color(color ?? "#000000")
      })
    );
      
    series.strokes.template.setAll({
      strokeWidth: 3,
    });

    series.data.setAll(data);

    return () => {
      root.dispose();
    };
  }, [data]);
    
  return <div id={chartId} className="w-full h-80" />;
};
