import React, { useEffect } from "react";

import { Box } from "@mui/material";
import JourneyCard from "components/LocationInsights/JourneyCard";
import { useSelector } from "react-redux";
import { Outlet, useSearchParams } from "react-router-dom";
import { useGetLocationFiltersQuery, useLazyGetLocationInsightsQuery } from "store/slices/locationSlice";
import { useDebounceCallback } from "usehooks-ts";

import CurrentFilters from "./Filters/CurrentFilters";
import Filters from "./Filters/Filters";
import InternalNav from "./Nav/InternalNav";

const InternalViewLayout = () => {
  const [currentParams] = useSearchParams();

  const { data: filterData } = useGetLocationFiltersQuery();
  const filterOptions = filterData?.["location-insights-internal"] || [];

  const [getLocationDetail] = useLazyGetLocationInsightsQuery();
  const { data, isLoading } = useSelector((state) => state.location);

  const fetchLoactionDetail = useDebounceCallback(() => {
    const params = Object.fromEntries(currentParams.entries());
    getLocationDetail({ params });
  }, 500);
  
  useEffect(() => {
    fetchLoactionDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams.toString()]);
  return (
    <Box px={4} py={3}>
      <Filters filterOptions={filterOptions} />

      <div className="mt-4">
        <InternalNav />
      </div>

      <div className="mt-4">
        <CurrentFilters />
      </div>

      <div className="mt-4 px-4 py-2">
        <Outlet context={{ data, isLoading, filterOptions }}/>
      </div>
      
      <JourneyCard />
      
    </Box>
  );
};

export default InternalViewLayout;
