import React from "react";

import HorizontalChartCard from "pages/LocationInsights/components/charts/HorizontalChartCard";

const PostingDuration = ({ data }) => {
  if (!data || !data.selectedposition || !data.allpositions) {
    return null;  
  }

  const groups = ["30 days or less", "90 days or less", "180 days or less"];
  const dataGroups = groups.map((group) => {
    const selectedPosition = data.selectedposition[group];
    const allPositions = data.allpositions[group] || {};
    return {
      group,
      showTitle: "true",
      data: [
        {
          label: "Selected Position",
          value: (+selectedPosition.ratio || 0) + "%",
          percentage: +selectedPosition.ratio || 0,
          additionalValue: new Intl.NumberFormat().format(+selectedPosition.count || 0),
          color: "#1292E8"
        },
        {
          label: "All Positions",
          value: (+allPositions.ratio || 0) + "%",
          percentage: +allPositions.ratio || 0,
          additionalValue: new Intl.NumberFormat().format(+allPositions.count || 0),
          color: "#EE538B"
        }
      ]
    };
  });
  
  return (
    <HorizontalChartCard header="Posting Duration" dataGroups={dataGroups} />
  );
};

export default PostingDuration;
