import React from "react";

import { Language } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import MoreInformationPopover from "components/MoreInformationPopover/MoreInformationPopover";
import AutodeskImg from "img/favicon-dark.svg";
import AutodeskImgLight from "img/favicon.png";
import HeaderCard from "pages/LocationInsights/components/HeaderCard";
import { StatCard } from "pages/LocationInsights/components/StatCard";
import { useOutletContext, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import AttritionByTenure from "./AttritionByTenure";
import AttritionTrends from "./AttritionTrends";
import helpText from "../../../helpText";

const AttritionDetail = () => {
  const { data, isLoading } = useOutletContext();
  const { id } = useParams();

  const attritionClassification = data?.metrics?.find((item) => item.metric_name === "attrition_classification");
  const attritionTrends = data?.metrics?.find((item) => item.metric_name === "attrition_rates");
  const locationAttritionRate = data?.metrics?.find((item) => item.metric_name === "attrition_year");
  const teamAttritionRate = data?.adsk_location_metrics?.find((item) => item.metric_name === "attrition_year");
  const attritionByTenure = data?.metrics?.find((item) => item.metric_name === "attrition_by_tenure");
  const attritionGlobalBenchmark = data?.metrics?.find((item) => item.metric_name === "attrition_ext_global_benchmark");

  if(isLoading) {
    return (
      <Grid item md={12}>
        <Box className="flex items-center justify-center w-full" sx={{ height: "calc(100vh - 400px)" }}>
          <BeatLoader color="#5C5470" />
        </Box>
      </Grid>
    );
  }

  // https://people-insights-tableau.autodesk.com/views/AttritionDashboard-New/Summary-New

  return (
    <Box>
      <HeaderCard
        question="What is the attrition pattern at this location?"
        linkText="Attrition dashboard"
        linkUrl={`/dashboard/${id}/location-insights/tableau?url=https://people-insights-tableau.autodesk.com/views/AttritionDashboard-New/Summary-New`}
        info={<MoreInformationPopover helpText={helpText.attrition}/>}
      />

      
      <Grid container columnSpacing={4}>
        <Grid item md={6} xs={12}>
          <div className="flex flex-col gap-6 p-6 rounded-3xl bg-[#f9f9f9] border border-[#e5e5e5]">
            <StatCard
              theme="dark"
              level={attritionClassification?.value?.toLowerCase()}
              mainStat={`${locationAttritionRate?.value?.total_attrition_rate}%`}
              mainStatLabel={{ title: "Total Attrition", value: "(past 12 months)" }}
              subStats={[
                {
                  value: "Autodesk",
                  title: `${teamAttritionRate?.value?.total_attrition_rate}%`,
                  icon: <img src={AutodeskImg} alt="Autodesk" className="aspect-square h-3 w-3" />
                },
                {
                  value: attritionGlobalBenchmark?.value?.label,
                  title: `${attritionGlobalBenchmark?.value?.total_attrition_rate ?? attritionGlobalBenchmark?.value?.total}%`,
                  icon: <Language sx={{ color: "white", fontSize: "14px" }} />
                }
              ]}
            />

            <StatCard
              mainStat={`${locationAttritionRate?.value?.voluntary_attrition_rate}%`}
              mainStatLabel={{ title: "Voluntary Attrition" }}
              subStats={[
                {
                  value: "Autodesk",
                  title: `${teamAttritionRate?.value?.voluntary_attrition_rate}%`,
                  icon: <img src={AutodeskImgLight} alt="Autodesk" className="aspect-square h-3 w-3" />
                },
                {
                  value: attritionGlobalBenchmark?.value?.label,
                  title: `${attritionGlobalBenchmark?.value?.voluntary_attrition_rate ?? attritionGlobalBenchmark?.value?.voluntary}%`,
                  icon: <Language sx={{ fontSize: "14px" }} />
                }
              ]}
            />

            <StatCard
              mainStat={`${locationAttritionRate?.value?.involuntary_attrition_rate}%`}
              mainStatLabel={{ title: "Involuntary Attrition" }}
              subStats={[
                {
                  value: "Autodesk",
                  title: `${teamAttritionRate?.value?.involuntary_attrition_rate}%`,
                  icon: <img src={AutodeskImgLight} alt="Autodesk" className="aspect-square h-3 w-3" />
                },
                {
                  value: attritionGlobalBenchmark?.value?.label,
                  title: `${attritionGlobalBenchmark?.value?.involuntary_attrition_rate ?? attritionGlobalBenchmark?.value?.involuntary}%`,
                  icon: <Language sx={{ fontSize: "14px" }} />
                }
              ]}
            />
          </div>
        </Grid>
      </Grid>

      <div className="mt-10">
        <AttritionTrends data={attritionTrends?.value ?? {}} />
      </div>

      <div className="mt-10">
        <AttritionByTenure data={attritionByTenure?.value ?? {}} />
      </div>
    </Box>
  );
};

export default AttritionDetail;
