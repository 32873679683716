
import HorizontalChartCard from "pages/LocationInsights/components/charts/HorizontalChartCard";

const SupplyDemand = ({ data }) => {
  const chartData = data ? [
    {
      label: "Supply",
      value: +data.supply,
      color: "#1292E8",
    },
    {
      label: "Demand",
      value: +data.demand,
      color: "#005D5D",
    }
  ] : [];

  return <HorizontalChartCard header="Supply and Demand" data={chartData} />;
};

export default SupplyDemand;
