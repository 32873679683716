import React from "react";

import { Grid } from "@mui/material";

import JobFunctionFilter from "./JobFunctionFilter";
import LocationFilter from "./LocationFilter";
import ManagementFilter from "./ManagementFilter";

const Filters = ({ filterOptions }) => {
  return (
    <div className="p-3 border border-gray-300 rounded-lg bg-[#f9f9f9]">
      <Grid container rowGap={4}>
        <Grid item xl={8} className="pr-3 border-r border-gray-300">
          <LocationFilter filterOptions={filterOptions} />
        </Grid>
        <Grid item xl={4} className="pl-3">
          <JobFunctionFilter jobOptions={filterOptions?.job_function ?? []} />
        </Grid>
        <Grid item xs={12}>
          <ManagementFilter filterOptions={filterOptions} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Filters;
