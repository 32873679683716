import React from "react";

import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import { Button } from "@mui/material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const DownloadExcelButton = ({ title, data, columns }) => {
  const downloadExcel = () => {
    const excelData = data.map(item => {
      return columns.reduce((acc, column) => {
        return {
          ...acc,
          [column.label]: item[column.key] 
        };
      }, {});
    });
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      XLSX.utils.json_to_sheet(excelData),
      "Sheet 1"
    );
    const arrayBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  
    const blob = new Blob([arrayBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, `${title ?? "Summary"}.xlsx`);
  };
  
  return (
    <Button onClick={downloadExcel} startIcon={<GetAppOutlinedIcon />} variant="outlined">
        Download Excel
    </Button>
  );
};

export default DownloadExcelButton;
