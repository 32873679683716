import React from "react";

import { StatCard } from "pages/LocationInsights/components/StatCard";
import { useParams, useSearchParams } from "react-router-dom";

const TimezoneOverview = ({ data }) => {
  const commonTimezone = data?.value?.common_tz;
  const commonTimezonePercent = data?.value?.common_tz_pct;
  const locations = data?.value?.locations;
  const [currentParams] = useSearchParams();
  const { id } = useParams();

  return <StatCard
    mainStat={commonTimezone}
    mainStatLabel={{ title: "Timezone", value: `(most common with ${Math.round(commonTimezonePercent)}% workers)` }}
    url={`/dashboard/${id}/location-insights/internal-view/timezone?${currentParams.toString()}`}
  />;
};

export default TimezoneOverview;
