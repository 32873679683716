import React from "react";

import PieChartCard from "pages/LocationInsights/components/charts/PieChartCard";
import { getPercentageString } from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/LocationInsightsInternalDetail/utils";

const colors = ["#CCCCCC", "#4589FF", "#A56EFF", "#009D9A", "#005D5D", "#6929C4"];

const EducationRanking = ({ data }) => {
  const totalValue = data.reduce((acc, item) => acc + +item.demandcount, 0);
  const chartData = data.map((item, index) => ({
    label: item.name,
    value: item.demandcount,
    additionalValue: getPercentageString(item.demandcount, totalValue),
    color: colors[item.id] || colors[index % colors.length],
  }));
  return (
    <PieChartCard header="Education level" data={chartData} />
  );
};

export default EducationRanking;
