import React from "react";

import { Box, Grid } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import InternalViewTable from "./InternalViewTable/InternalViewTable";
import AttritionOverview from "./LocationInsightsInternalDetail/Tabs/Attrition/AttritionOverview";
import HeadcountOverview from "./LocationInsightsInternalDetail/Tabs/Headcount/HeadcountOverview";
import LeadershipDepthOverview from "./LocationInsightsInternalDetail/Tabs/LeadershipDepth/LeadershipDepthOverview";
import LeadershipDepthRatio from "./LocationInsightsInternalDetail/Tabs/LeadershipDepth/LeadershipDepthRatio";
import RecruitmentOverview from "./LocationInsightsInternalDetail/Tabs/Recruitment/RecruitmentOverview";
import TeamDistributionOverview from "./LocationInsightsInternalDetail/Tabs/TeamDistribution/TeamDistributionOverview";
import TimezoneOverview from "./LocationInsightsInternalDetail/Tabs/Timezone/TimezoneOverview";

const LocationInsightsInternalView = () => {
  const { data, isLoading } = useOutletContext();

  const headCountSummaryData = data?.metrics?.find((item) => item.metric_name === "headcount_summary");
  const attritionOverviewData = data?.metrics?.find((item) => item.metric_name === "attrition_rates");
  const timezoneOverviewData = data?.metrics?.find((item) => item.metric_name === "tz_summary");
  const leadershipDepthOverviewData = data?.metrics?.find((item) => item.metric_name === "headcount_mgmt_level");
  const distributionOverviewData = data?.metrics?.find((item) => item.metric_name === "team_distribution_summary");
  const budgetedAndOpenRolesData = data?.metrics?.find((item) => item.metric_name === "tbh_req_summary");
  const locationLeadershipDepthRatios = data?.metrics?.find((item) => item.metric_name === "leadership_depth_ratios");
  const teamLeadershipDepthRatios = data?.adsk_location_metrics?.find((item) => item.metric_name === "leadership_depth_ratios");
  const leadershipDepth = data?.metrics?.find((item) => item.metric_name === "leadership_depth");
  const tableData = data?.raw_data?.[0].rows || [];

  if(isLoading) {
    return (
      <Grid item md={12}>
        <Box className="flex items-center justify-center w-full" sx={{ height: "calc(100vh - 400px)" }}>
          <BeatLoader color="#5C5470" />
        </Box>
      </Grid>
    );
  }

  return (
    <Box>
      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={8}>
          <div className="p-6 rounded-3xl bg-[#f9f9f9] flex gap-6">
            <div className="flex flex-col gap-6 w-full">
              <HeadcountOverview data={headCountSummaryData} />
              <LeadershipDepthOverview data={leadershipDepthOverviewData} />
            </div>
            <div className="flex flex-col gap-6 w-full">
              <LeadershipDepthRatio data={leadershipDepth} locationRatio={locationLeadershipDepthRatios} teamRatio={teamLeadershipDepthRatios} />
              <TeamDistributionOverview data={distributionOverviewData} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="flex flex-col gap-6 w-full">
            <TimezoneOverview data={timezoneOverviewData} />
            <RecruitmentOverview data={budgetedAndOpenRolesData} title="Budgeted and open roles" />
            <AttritionOverview data={attritionOverviewData} />
          </div>
        </Grid>

        <Grid item xs={12} mt={4}>
          <InternalViewTable data={tableData} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LocationInsightsInternalView;
