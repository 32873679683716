import React, { useState } from "react";

import { Add } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Checkbox, TextField, Autocomplete } from "@mui/material";
import { useGetTimezoneDistributionMutation } from "store/slices/locationSlice";

const DEFAULT_TIMEZONE_ID = "America/Los_Angeles";

const ScenarioLocations = ({ scenarioLocations, baseTimezone, onApply }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [getTimezoneDistribution] = useGetTimezoneDistributionMutation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApply = () => {
    setIsLoading(true);
    const data = {
      id: 1,
      metric_name: "scenario_locs",
      value: selectedLocations.reduce((acc, curr) => {
        acc[curr.label] = curr;
        return acc;
      }
      , {})
    };

    if (baseTimezone.value !== DEFAULT_TIMEZONE_ID && selectedLocations.length > 0) {
      getTimezoneDistribution({ baseTimezone: baseTimezone.value, data: data }).then(res => {
        if (res?.data) {
          onApply(res?.data);
        }
      }).finally(() => {
        setIsLoading(false);
        handleClose();
      }); 
    } else {
      onApply(data);
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <div>
      <Button variant="text" onClick={handleOpen} startIcon={<Add />} disabled={!scenarioLocations || scenarioLocations.length === 0}>
        Add Scenario Cities
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Scenario Locations</DialogTitle>
        <DialogContent className="w-[500px]">
          <Autocomplete
            disableCloseOnSelect
            limitTags={2}
            multiple
            options={scenarioLocations}
            value={selectedLocations}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Scenario Locations" placeholder="Select locations" />
            )}
            onChange={(event, value) => setSelectedLocations(value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained" disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={handleApply} color="primary" variant="contained" disabled={isLoading} loading={isLoading.toString()}>
            Apply Changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ScenarioLocations;
