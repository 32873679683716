export const NoDataCard = ({ title }) => {
  return (
    <div className="flex flex-col bg-white rounded-3xl px-6 pt-6 border-[#e5e5e5] border">
      <div className="border-b border-[#e5e5e5] pb-2">
        <p className="font-bold">{title}</p>
      </div>
      <div className="flex items-center justify-center w-full h-[60px]">
        <p className="text-sm text-black/60">No data available</p>
      </div>
    </div>
  );
};
