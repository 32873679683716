import React from "react";

import HorizontalChartCard from "pages/LocationInsights/components/charts/HorizontalChartCard";

const COLOR_MAP = {
  team: "#0F62FE",
  location: "#78A9FF",
};

const groups = ["Directors", "Senior Directors", "VPs", "SVPs and above"];

const BarChart = ({ teamValue, locationValue, locationName }) => {
  const chartData = [];

  // location
  groups.forEach((group) => {
    const data = [];
    data.push({
      label: `${group} (Autodesk)`,
      value: +teamValue[group] ?? 0,
      color: COLOR_MAP.team,
    });
    data.push({
      label: `${group} (Team)`,
      value: +locationValue[group] ?? 0,
      color: COLOR_MAP.location,
    });
    chartData.push({
      group,
      data
    });
  });

  return (
    <HorizontalChartCard header={`Leadership depth, ${locationName}`} dataGroups={chartData} />
  );
};

export default BarChart;
