import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import MoreInformationPopover from "components/MoreInformationPopover/MoreInformationPopover";
import HeaderCard from "pages/LocationInsights/components/HeaderCard";
import { StatCard } from "pages/LocationInsights/components/StatCard";
import { useOutletContext } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import DemandTrend from "./DemandTrend";
import SupplyDemand from "./SupplyDemand";
import { SupplyDemandRatio } from "../../Overview/Overview";
import helpText from "../helpText";

const SupplyAndDemandDetail = () => {
  const { externalData, isLoading } = useOutletContext();

  if(isLoading) {
    return (
      <Grid item md={12}>
        <Box className="flex items-center justify-center w-full" sx={{ height: "calc(100vh - 400px)" }}>
          <BeatLoader color="#5C5470" />
        </Box>
      </Grid>
    );
  }

  if(!externalData || externalData.length !== 1) {
    return (
      <Box className="w-full" sx={{ height: "calc(100vh - 400px)" }}>
        <div className="w-3/4 mx-auto">
          <Typography mt={4} variant="body1" textAlign="center" fontWeight="bold" fontSize="24px">
          No data available. Try selecting or changing the location and job title filters
          </Typography>
        </div>
      </Box>
    );
  }

  const data = externalData[0];

  return (
    <Box>
      <HeaderCard
        question="What is the talent volume at this location?"
        info={<MoreInformationPopover helpText={helpText.supplyDemand}/>}
      />
      <Grid container mt={4} columnSpacing={4}>
        <Grid item lg={4}>
          <div className="flex flex-col gap-6 w-full">
            <SupplyDemandRatio data={data.supplyDemandRatio} />
            <StatCard mainStat={new Intl.NumberFormat("en-US").format(data.details_demand_active)} mainStatLabel={{ title: "Active demand" }}/>
          </div>
        </Grid>
        <Grid item lg={4}>
          <SupplyDemand data={data?.details_demand_supply} />
        </Grid>
        <Grid item xs={12} lg={12}>
          <DemandTrend data={data?.details_demand_trend} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SupplyAndDemandDetail;
