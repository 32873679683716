import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { insightApi } from "../../apis/api";

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

export const commentApi = insightApi.injectEndpoints({
  endpoints: (builder) => ({
    getComment: builder.query({
      query: (params) => ({
        url: "/comment-search",
        params: {
          token: Cookies.get("storedAccessToken"),
          ...params,
        },
      }),
    }),
    searchComment: builder.mutation({
      query: (data) => ({
        url: "/comment-search",
        method: "POST",
        params: {
          token: Cookies.get("storedAccessToken"),
        },
        data,
      }),
    }),
    addCommentFeedback: builder.mutation({
      query: (data) => ({
        url: "/comment-feedback",
        method: "POST",
        params: {
          token: Cookies.get("storedAccessToken"),
        },
        data,
      }),
      invalidatesTags: ["CommentFeedback"]
    }),
    getCommentFeedback: builder.query({
      query: (params) => ({
        url: "/comment-feedback",
        params: {
          token: Cookies.get("storedAccessToken"),
          ...params,
        },
      }),
      providesTags: ["CommentFeedback"]
    }),
    updateCommentFeedback: builder.mutation({
      query: (data) => ({
        url: "/comment-feedback",
        method: "PUT",
        params: {
          token: Cookies.get("storedAccessToken"),
        },
        data,
      }),
    }),
    generateComments: builder.mutation({
      query: (data) => ({
        url: "/generate-comment-summary",
        method: "POST",
        params: {
          token: Cookies.get("storedAccessToken"),
        },
        data,
      })
    }),
    getApplicationFilters: builder.query({
      query: (data) => ({
        url: "/application-filters",
        params: {
          token: Cookies.get("storedAccessToken"),
          ...data
        },
      })
    }),
    getCommentsByPage: builder.mutation({
      query: (data) => ({
        url: "/comment-search",
        method: "POST",
        params: {
          token: Cookies.get("storedAccessToken"),
        },
        data
      })
    }),
    getCommentChartData: builder.mutation({
      query: (data) => ({
        url: "/comment-chart-data",
        method: "POST",
        params: {
          token: Cookies.get("storedAccessToken"),
        },
        data
      })
    }),
  }),
});

export const commentSlice = createSlice({
  name: "comment-search",
  initialState,
  reducers: {
    clearComments: (state) => {
      state.data = [];
    },
    updateComments: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      commentApi.endpoints.searchComment.matchPending,
      (state) => {
        state.isLoading = true;
        state.isError = false;
      }
    );
    // When our request is fulfilled:
    builder.addMatcher(
      commentApi.endpoints.searchComment.matchFulfilled,
      (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.data = action.payload?.data?.Get?.Glint || [];
      }
    );
    // When our request is rejected:
    builder.addMatcher(
      commentApi.endpoints.searchComment.matchRejected,
      (state) => {
        state.isLoading = false;
        state.isError = true;
      }
    );
  },
});

export const {
  useGetCommentQuery,
  useSearchCommentMutation,
  useGetCommentFeedbackQuery,
  useAddCommentFeedbackMutation,
  useUpdateCommentFeedbackMutation,
  useGenerateCommentsMutation,
  useGetApplicationFiltersQuery,
  useGetCommentsByPageMutation,
  useGetCommentChartDataMutation,
} = commentApi;

export const { clearComments, updateComments } = commentSlice.actions;
