import clsx from "clsx";

const levelBarClasses = {
  critical: ["bg-[#666]", "bg-[#666]", "bg-[#666]", "bg-red-500"],
  low: ["bg-[#666]", "bg-[#666]", "bg-orange-500", "bg-orange-500"],
  medium: ["bg-[#666]", "bg-yellow-500", "bg-yellow-500", "bg-yellow-500"],
  high: ["bg-green-700", "bg-green-700", "bg-green-700", "bg-green-700"],
  neutral: ["bg-[#D0E2FF]", "bg-[#D0E2FF]", "bg-[#D0E2FF]", "bg-[#D0E2FF]"]
};

const levelBarClassesInverse = {
  critical: ["bg-red-500", "bg-red-500", "bg-red-500", "bg-red-500"],
  medium: ["bg-[#666]", "bg-[#666]", "bg-orange-500", "bg-orange-500"],
  low: ["bg-[#666]", "bg-yellow-500", "bg-yellow-500", "bg-yellow-500"],
  high: ["bg-[#666]", "bg-[#666]", "bg-[#666]", "bg-green-700"],
  neutral: ["bg-[#D0E2FF]", "bg-[#D0E2FF]", "bg-[#D0E2FF]", "bg-[#D0E2FF]"]
};

const levelSolidClasses = {
  critical: "bg-red-500",
  low: "bg-orange-500",
  medium: "bg-yellow-500",
  high: "bg-green-700",
  neutral: "bg-[#D0E2FF]"
};

const levelSolidClassesInverse = {
  critical: "bg-green-700",
  medium: "bg-orange-500",
  low: "bg-yellow-500",
  high: "bg-red-500",
  neutral: "bg-[#D0E2FF]"
};

// level: 'low' | 'medium' | 'high' | 'critical' | 'neutral';
// theme: 'light' | 'dark';
// config: { inverse?: boolean; solid?: boolean; }
const LevelIndicator = ({ level, theme, config = {} }) => {
  const classes = config?.inverse == true ? levelBarClassesInverse[level] : levelBarClasses[level];
  if (config?.solid) {
    const bg = config?.inverse == true ? levelSolidClassesInverse[level] : levelSolidClasses[level];
    return <div className={clsx("w-[56px] h-[64px] ml-auto rounded", bg)} />;
  }
  return <div className={clsx("min-w-[64px] rounded p-2 flex flex-col items-center gap-1 ml-auto", { "bg-[#f9f9f9]": theme === "light", "bg-[#161616]": theme === "dark" })}>
    {classes.map((barClass, idx) => <div key={idx} className={clsx("h-2 w-full rounded-full", barClass)} />)}
    <p className={clsx("uppercase", {
      "text-black/60": theme === "light",
      "text-white/60": theme === "dark",
    })}>{level}</p>
  </div>;
};

export default LevelIndicator;
