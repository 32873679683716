import React from "react";

import HorizontalChartCard from "pages/LocationInsights/components/charts/HorizontalChartCard";
import { getPercentageString } from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/LocationInsightsInternalDetail/utils";

const Competitors = ({ data }) => {
  
  const totalValue = Object.values(data).reduce((acc, curr) => acc + Number(curr), 0);

  const chartData = data ? Object.entries(data)
    .map(([key, value]) => ({ label: key, value: +Number(value).toFixed(2) }))
    .sort((a, b) => b.value -a.value)
    : [];

  return (
    <HorizontalChartCard header="Competitors" subheader="Employers' share of the job market (%)" data={chartData} color="#002D9C" maxCount={15} />
  );
};

export default Competitors;
