import { Autocomplete, Grid, TextField } from "@mui/material";

const hourOptions = [{
  label: "Regular Hours (9am - 6pm)",
  value: "regular",
},
{
  label: "Extended Hours (7am - 9pm)",
  value: "extended",
},
];

const workerTypeOptions = [{
  label: "All",
  value: "All",
},
{
  label: "People Managers only",
  value: "manager",
},
{
  label: "ICs only",
  value: "ic",
}
];

const sortOptions = [{
  label: "None",
  value: "",
}, 
{
  label: "No. of workers",
  value: "workers",
}, {
  label: "Regular hours overlap",
  value: "regular",
}, {
  label: "Extended hours overlap",
  value: "extended",
}];

export const TimezoneFilters = ({ baseTimezoneOptions, filterState, setFilterState }) => {
  
  return (
    <Grid container>
      <Grid item xs={12} md={2}>
        <div className="flex flex-col gap-2 pr-3 mr-3 border-r border-[#e5e5e5]">
          <p className="text-sm font-bold">Baseline</p>
          <Autocomplete
            id="city"
            value={filterState.baseTimezone}
            onChange={(event, newValue) => {
              setFilterState({ ...filterState, baseTimezone: newValue });
            }}
            options={baseTimezoneOptions}
            renderInput={(params) => (
              <TextField {...params} label="City" variant="outlined" size="small" />
            )}
            disableClearable
          />
        </div>
      </Grid>
      <Grid item xs={12} md={2}>
        <div className="flex flex-col gap-2 mr-3">
          <p className="text-sm font-bold">Time comparison</p>
          <Autocomplete
            id="baseline-hours"
            value={filterState.baseLineHours}
            onChange={(event, newValue) => {
              setFilterState({ ...filterState, baseLineHours: newValue });
            }}
            options={hourOptions}
            renderInput={(params) => (
              <TextField {...params} label="Baseline hours" variant="outlined" size="small" />
            )}
            disableClearable
          />
        </div>
      </Grid>
      <Grid item xs={12} md={2}>
        <div className="flex flex-col gap-2">
          <p className="text-sm font-bold invisible">A</p>
          <Autocomplete
            id="otjer-hours"
            value={filterState.otherHours}
            onChange={(event, newValue) => {
              setFilterState({ ...filterState, otherHours: newValue });
            }}
            options={hourOptions}
            renderInput={(params) => (
              <TextField {...params} label="vs other timezone hours" variant="outlined" size="small" />
            )}
            disableClearable
          />
        </div>
      </Grid>
      <Grid item xs={12} md={2}></Grid>
      <Grid item xs={12} md={2}>
        <div className="flex flex-col gap-2 mr-3">
          <p className="text-sm font-bold invisible">A</p>
          <Autocomplete
            id="worker-type"
            value={filterState.workerType}
            onChange={(event, newValue) => {
              setFilterState({ ...filterState, workerType: newValue });
            }}
            options={workerTypeOptions}
            renderInput={(params) => (
              <TextField {...params} label="Worker type" variant="outlined" size="small" />
            )}
            disableClearable
          />
        </div>
      </Grid>
      <Grid item xs={12} md={2}>
        <div className="flex flex-col gap-2">
          <p className="text-sm font-bold invisible">A</p>
          <Autocomplete
            id="sort"
            value={filterState.sort}
            onChange={(event, newValue) => {
              setFilterState({ ...filterState, sort: newValue });
            }}
            options={sortOptions}
            renderInput={(params) => (
              <TextField {...params} label="Sort by" variant="outlined" size="small" />
            )}
            disableClearable
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default TimezoneFilters;
