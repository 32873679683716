import React from "react";

import { Box, Grid } from "@mui/material";
import HeaderCard from "pages/LocationInsights/components/HeaderCard";
// import { StatCard } from "pages/LocationInsights/components/StatCard";
import { useOutletContext } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import TimezoneCompatibility from "./TimezoneCompatibility";
import TimezoneDistribution from "./TimezoneDistribution";

const TimezoneDetail = () => {
  const { data, isLoading, filterOptions } = useOutletContext();
  const baseTimezoneOptions = Object.entries(filterOptions.baseline_timezone ?? {}).map(([key, value]) => ({ label: key, value }));

  const timezoneDistribution = data?.metrics?.find((item) => item.metric_name === "tz_distribution");
  const timezoneCapability = data?.metrics?.find((item) => item.metric_name === "tz_compatibility");
  const scenarioLocations = data?.adsk_location_metrics?.find((item) => item.metric_name === "scenario_locations");

  if(isLoading) {
    return (
      <Grid item md={12}>
        <Box className="flex items-center justify-center w-full" sx={{ height: "calc(100vh - 400px)" }}>
          <BeatLoader color="#5C5470" />
        </Box>
      </Grid>
    );
  }

  return (
    <Box>
      <HeaderCard question="Does this location have a suitable timezone?" info="These time zone overlaps may change due to Daylight Savings Time" />
      <Grid container columnSpacing={4}>
        <Grid item md={4} xs={12}>
          <TimezoneCompatibility data={timezoneCapability?.values} />
        </Grid>
        {/* <Grid item md={4} xs={12}>
          <StatCard mainStat={`${Math.round(timezoneCapability?.values?.avg_pairwise_overlap ?? 0)} hour`} mainStatLabel={{ title: "Avg. Pairwise Overlap", icon: <div className="h-3 w-3 rounded-full bg-[#FF832B]"/> }} />
        </Grid> */}
      </Grid>
      <div className="mt-4">
        <TimezoneDistribution timezoneDistribution={timezoneDistribution} baseTimezoneOptions={baseTimezoneOptions} scenarioLocations={scenarioLocations} />
      </div>
    </Box>
  );
};

export default TimezoneDetail;
