import React from "react";

import HorizontalChartCard from "pages/LocationInsights/components/charts/HorizontalChartCard";
import { StatCard } from "pages/LocationInsights/components/StatCard";
import { useParams, useSearchParams } from "react-router-dom";

import { getPercentageString } from "../../utils";

const getWorkerTypeBreakdown = (data) => {
  const { "Employees": employees, "Contingents (excl. Assoc & OSP)": contingents } = data || {};

  const totalValue = (employees ?? 0) + (contingents ?? 0);
  return [
    {
      label: "Employees",
      value: employees,
      additionalValue: getPercentageString(employees, totalValue),
    },
    {
      label: "Contingents (excl. Assoc & OSP)",
      value: contingents,
      additionalValue: getPercentageString(contingents, totalValue),
    }
  ];
};

const getManagementLevelBreakdown = (data) => {
  const { "Individual Contributors": individualContributors, "Managers": managers, "Directors and above": directorsAndAbove } = data || {};
  const totalValue = (individualContributors ?? 0) + (managers ?? 0) + (directorsAndAbove ?? 0);

  return [
    {
      label: "Individual Contributors",
      value: individualContributors,
      additionalValue: getPercentageString(individualContributors, totalValue),
    },
    {
      label: "Managers",
      value: managers,
      additionalValue: getPercentageString(managers, totalValue),
    },
    {
      label: "Leadership (directors and above)",
      value: directorsAndAbove,
      additionalValue: getPercentageString(directorsAndAbove, totalValue),
    }
  ];
};

const HeadcountOverview = ({ data }) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();

  const { "Total (excl. Assoc & OSP)": totalValue, ...rest } = data?.value || {};
  

  return (
    <div>
      <StatCard
        mainStat={new Intl.NumberFormat("en-US").format(totalValue)} 
        mainStatLabel={{ 
          title: "Total workers",
          value: "(including employees and contingent workers)",
        }}
        theme="dark"
        url={`/dashboard/${id}/location-insights/internal-view/headcount?${currentParams.toString()}`}
      />

      <HorizontalChartCard 
        header="Management level breakdown" 
        data={getManagementLevelBreakdown(rest)} color="#8A3FFC"
        className="mt-4"
        url={`/dashboard/${id}/location-insights/internal-view/headcount?${currentParams.toString()}`}
      />

      <HorizontalChartCard 
        header="Headcount" 
        subheader="Worker type breakdown"
        data={getWorkerTypeBreakdown(rest)} color="#009D9A"
        className="mt-4"
        url={`/dashboard/${id}/location-insights/internal-view/headcount?${currentParams.toString()}`}
      />
    </div>
  );
};

export default HeadcountOverview;
