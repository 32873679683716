import React from "react";

import { Box, Grid } from "@mui/material";
import MoreInformationPopover from "components/MoreInformationPopover/MoreInformationPopover";
import HeaderCard from "pages/LocationInsights/components/HeaderCard";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import ForecastedHeadcount from "./ForecastedHeadcount";
import TbhJobFunctions from "./TbhJobFunctions";
import TopLocations from "./TopLocations";
import helpText from "../../../helpText";
import { getLocationNameFromQueryParams, getTeamFromQueryParams } from "../../../utils";
import RecruitmentOverview from "../RecruitmentOverview";

const RecruitmentDetail = () => {
  const [currentParams] = useSearchParams();
  const { data, isLoading } = useOutletContext();
  const locationName = getLocationNameFromQueryParams(currentParams);
  const teamName = getTeamFromQueryParams(currentParams);

  if(isLoading) {
    return (
      <Grid item md={12}>
        <Box className="flex items-center justify-center w-full" sx={{ height: "calc(100vh - 400px)" }}>
          <BeatLoader color="#5C5470" />
        </Box>
      </Grid>
    );
  }
  
  const teamForecastedHeadcount = data?.adsk_location_metrics?.find((item) => item.metric_name === "tbh_forecasted_headcount");
  const locationForecastedHeadcount = data?.metrics?.find((item) => item.metric_name === "tbh_forecasted_headcount");
  const teamTbhJobFunctions = data?.adsk_location_metrics?.find((item) => item.metric_name === "tbh_job_function_distribution");
  const locationTbhJobFunctions = data?.metrics?.find((item) => item.metric_name === "tbh_job_function_distribution");
  const locationTbhReqSummary = data?.metrics?.find((item) => item.metric_name === "tbh_req_summary");
  const teamTbhReqSummary = data?.adsk_location_metrics?.find((item) => item.metric_name === "tbh_req_summary");
  const topTbhLocationAutodesk = data?.metrics?.find((item) => item.metric_name === "top_tbh_locations_fy");
  const topTbhLocationTeam = data?.metrics?.find((item) => item.metric_name === "team_tbhs_all_locations");

  return (
    <Box>
      <HeaderCard
        question="What are the budgeted Employee headcount growth and roles at this location?"
        info={<MoreInformationPopover helpText={helpText.openBudgetedRoles} />}
      />
      <Grid container columnSpacing={3}>
        <Grid item xs={12} md={6}>
          <p className="text-xl font-extrabold">Total TBH</p>
          <div className="flex gap-4 mt-4">
            <RecruitmentOverview data={teamTbhReqSummary} title="Autodesk" colors={{ requisition_count: "#00000", tbh_count: "#009D9A" }} className="w-1/2" />
            <RecruitmentOverview data={locationTbhReqSummary} title="Team" className="w-1/2" />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <p className="text-xl font-extrabold">TBH by job function at {locationName}</p>
          <div className="flex gap-4 mt-4">
            <div className="w-1/2">
              <TbhJobFunctions title="Autodesk" data={teamTbhJobFunctions?.value} location={locationName} max={6} />
            </div>
            <div className="w-1/2">
              <TbhJobFunctions title="Team" data={locationTbhJobFunctions?.value} location={locationName} max={6} />
            </div>
          </div>
        </Grid>
        {/* <Grid item xs={2}>
          <TeamSummary data={teamTbhReqSummary?.value} />
        </Grid>
        <Grid item xs={5}>
          <TeamForecastedHeadcount data={teamForecastedHeadcount?.value} location={locationName}/>
        </Grid>
        <Grid item xs={5}>
          <TeamTbhJobFunctions data={teamTbhJobFunctions?.value} location={locationName} />
        </Grid>
        <Grid item xs={2}>
          <LocationSummary data={locationTbhReqSummary?.value} />
        </Grid>
        <Grid item xs={5}>
          <LocationForecastedHeadcount data={locationForecastedHeadcount?.value} location={locationName} team={teamName} />
        </Grid>
        <Grid item xs={5}>
          <LocationTbhJobFunctions data={locationTbhJobFunctions?.value} location={locationName} team={teamName} />
        </Grid>
        <Grid item xs={12}>
          <TopLocations autodeskData={topTbhLocationAutodesk?.value} teamData={topTbhLocationTeam?.value} />
        </Grid> */}
      </Grid>

      <div className="mt-10">
        <ForecastedHeadcount teamData={teamForecastedHeadcount?.value} locationData={locationForecastedHeadcount?.value} location={locationName}/>
      </div>

      <div className="mt-10">
        <TopLocations autodeskData={topTbhLocationAutodesk?.value ?? {}} teamData={topTbhLocationTeam?.value ?? {}} />
      </div>
    </Box>
  );
};

export default RecruitmentDetail;
