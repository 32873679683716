import { InfoOutlined } from "@mui/icons-material";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const HeaderCard = ({ question, linkText, linkUrl, info }) => {
  return <div className="flex flex-col p-6 bg-[#f9f9f9] rounded-xl gap-1 mb-6">
    <div className="flex items-center">
      <p className="text-lg font-bold">{question}</p>
      {linkText ? <p className="ml-auto text-sm text-black/60">
        For more insights, go to <Link 
          component={RouterLink} 
          sx={{ color: "blue", cursor: "pointer" }} 
          to={linkUrl}>
          {linkText}
        </Link>
      </p> : null }
    </div>
    <div className="flex items-center">
      <InfoOutlined sx={{ mr: 1, fontSize: 16 }} />
      <p>{info}</p>
    </div>
  </div>;
};

export default HeaderCard;
