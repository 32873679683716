import { useState } from "react";

import { OpenInNew } from "@mui/icons-material";
import { Pagination } from "@mui/material";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "utils/number";

/* interface Props {
    header: string;
    subheader: string;
    data: {
      label: string;
      value: string | number;
      percentage?: number
      additionalValue: string | number;
      color?: string;
    }[];
    dataGroups?: {
        group: string;
        showTitle?: string;
        data: {
          label: string;
          value: string | number;
          percentage?: number;
          additionalValue: string | number;
          color?: string;
        }[];
    }[];
    color: string;
    url?: string;
    className?: string;
    maxCount?: number;
} */
const HorizontalChartCard = ({ header, subheader, data = [], color = "black", dataGroups, className, url, maxCount }) => {
  const navigate = useNavigate();
  const maxValue = Math.max(...data.map((item) => Number(item.value)));

  const [currentPage, setCurrentPage] = useState(1);
  
  const startIndex = (currentPage - 1) * maxCount;
  const paginatedData = data.slice(startIndex, startIndex + maxCount);

  return <div onClick={() => {
    if (url) {
      navigate(url);
    }
  }} className={clsx("flex flex-col bg-white rounded-3xl p-6 border-[#e5e5e5] border group", { "hover:border-black cursor-pointer": url }, className)}>
    <div className="border-b border-[#e5e5e5] pb-2 mb-2">
      <p className={clsx("font-bold flex items-center gap-1", {
        "group-hover:underline": url,
      })}>
        {header}
        <div className={clsx("hidden", { "group-hover:block": url })}><OpenInNew style={{ fontSize: "16px" }} /></div>
      </p>
      <p className="text-sm text-black/60 line-clamp-1">{subheader}</p>
    </div>
    {
      dataGroups && dataGroups.length > 0
        ? dataGroups.map((group) => {
          const groupMaxValue = Math.max(...group.data.map((item) => item.value));
          return <div key={group.group} className="w-full px-2 py-4 even:bg-[#f9f9f9] rounded-lg">
            {group.showTitle && <p className="uppercase text-sm">{group.group}</p>}
            {
              group.data.map((item) => {
                const width = item.percentage ? `${item.percentage}%` : `${(item.value / groupMaxValue) * 100}%`;
                return <div key={item.label} className="mt-4 w-full">
                  <div className="w-full flex items-center border-b border-[#e5e5e5]">
                    <p className="text-sm">{item.label}</p>
                    <div className="ml-auto flex items-center gap-1">
                      <p className="text-xs text-black/60">{formatNumber(item.additionalValue)}</p>
                      <p className="text-sm font-bold">{formatNumber(item.value)}</p>
                    </div>
                  </div>
                  <div className={clsx("h-4 rounded-md")} style={{ width: width, backgroundColor: item.color ?? color }}></div>
                </div>;
              })
            }
          </div>;
        })
        : (maxCount ? paginatedData : data).map((item) => {
          const width = item.percentage ? `${item.percentage}%` : `${(Number(item.value) / maxValue) * 100}%`;
          return <div key={item.label} className="mt-4 w-full">
            <div className="w-full flex items-center border-b border-[#e5e5e5]">
              <p className="text-sm">{item.label}</p>
              <div className="ml-auto flex items-center gap-1">
                <p className="text-xs text-black/60">{formatNumber(item.additionalValue)}</p>
                <p className="text-sm font-bold">{formatNumber(item.value)}</p>
              </div>
            </div>
            <div className={clsx("h-4 rounded-md")} style={{ width: width, backgroundColor: item.color ?? color }}></div>
          </div>;
        })
    }
    {
      data && maxCount ? <div className="mt-6 flex justify-center">
        <Pagination variant="outlined" shape="rounded" count={Math.ceil(data.length / maxCount)} page={currentPage} onChange={(event, page) => setCurrentPage(page)} />
      </div> : null
    }
  </div>;
};

export default HorizontalChartCard;
