import React from "react";

import { NoDataCard } from "pages/LocationInsights/components/NoDataCard";
import { StatCard } from "pages/LocationInsights/components/StatCard";
import { isObjectEmpty } from "utils/object";

import { getLevelValue } from "../../../utils";

const TimezoneCompatibility = ({ data }) => {

  if (!data || (Array.isArray(data) && data.length === 0) || isObjectEmpty(data)) {
    return <NoDataCard title="Timezone compatibility" />;
  }

  return <StatCard theme="dark" mainStat={data.score} mainStatLabel={{ title: "Timezone compatibility" }} level={getLevelValue(data.score)}/>;
};

export default TimezoneCompatibility;

/**
 * return (
    <Grid display="flex" spacing={4} container>
      <Grid item lg={5} xs={12}>
        <Box width="100%" display="flex" height="200px" justifyContent="center" flexDirection="column" border="1px solid grey" mr={2}>
          <Typography variant="h1" textAlign="center" fontSize="32px" mb={2} fontWeight="bold">
            {data.score}
          </Typography>
          <Typography variant="h6" textAlign="center" fontWeight="bold">
            Time zone compatibility within the team
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={7} xs={12} display="flex" alignItems="center">
        <ul>
          {Object.values(data.narrative).map((item, index) => (
            <li key={index} style={{ fontsize: "24px", fontWeight: "bold", marginTop: "12px", marginBottom: "12px" }}>
              {item}
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
 */
