import React, { useEffect } from "react";

import { Autocomplete, Box, FormControl, InputLabel, Link, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const ManagementFilter = ({ filterOptions }) => {
  const [currentParams, setCurrentParams] = useSearchParams();
  
  const division1 = currentParams.get("division") || "";
  const division2 = currentParams.get("l2") || "";
  const division3 = currentParams.get("l3") || "";
  const division4 = currentParams.get("l4") || "";

  const division1Options = filterOptions?.division || [];
  const division2Options = filterOptions?.management_tier_2?.[division1] || [];
  const division3Options = filterOptions?.management_tier_3?.[division2] || [];
  const division4Options = filterOptions?.management_tier_4?.[division3] || [];

  /* useEffect(() => {
    if (!currentParams.get("division") && division1Options.includes("Autodesk")) {
      currentParams.set("division", "Autodesk");
      setCurrentParams(currentParams);
    }
  }, [division1Options]); */
  
  const handleChangeDivision1 = (event) => {
    if (event.target.value === "") {
      currentParams.delete("division");
    } else {
      currentParams.set("division", event.target.value);  
    }
    currentParams.delete("l2");
    currentParams.delete("l3");
    currentParams.delete("l4");
    setCurrentParams(currentParams);
  };

  const handleChangeDivision2 = (value) => {
    if (!value) {
      currentParams.delete("l2");
    } else {
      currentParams.set("l2", value);
    }
    currentParams.delete("l3");
    setCurrentParams(currentParams);
  };

  const handleChangeDivision3 = (value) => {
    if (!value) {
      currentParams.delete("l3");
    } else {
      currentParams.set("l3", value);
    }
    currentParams.delete("l4");
    setCurrentParams(currentParams);
  };

  const handleChangeDivision4 = (value) => {
    if (!value) {
      currentParams.delete("l4");
    } else {
      currentParams.set("l4", value);
    }
    setCurrentParams(currentParams);
  };
  
  const handleClickClear = () => {
    currentParams.delete("division");
    currentParams.delete("l2");
    currentParams.delete("l3");
    currentParams.delete("l4");
    setCurrentParams(currentParams);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <Typography variant="body1" gutterBottom>Team</Typography>
        <Link
          component="button"
          variant="body1"
          onClick={handleClickClear}
          underline="always"
        >
          Reset
        </Link>
      </div>
      <Box sx={{ display: "flex", gap: 2 }}>

        <FormControl size="small" sx={{ flexGrow: 1, flexBasis: 0, backgroundColor: "white" }}>
          <InputLabel id="demo-select-small-label">Division</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            label="Division"
            onChange={handleChangeDivision1}
            value={division1}
          >
            {[...division1Options].sort((a, b) => a.localeCompare(b)).map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ flexGrow: 1, flexBasis: 0, backgroundColor: "white" }}>
          <Autocomplete
            label="Management (L2)"
            value={division2}
            onChange={(_e, values)=>handleChangeDivision2(values)}
            size="small"
            ChipProps={{
              variant: "contained",
              color: "primary",
            }}
            options={[...division2Options]?.sort((a, b) => a.localeCompare(b)) || []}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Management (L2)"
                placeholder="Management (L2)"
              />
            )}
            disabled={division2Options.length === 0}
          />
        </FormControl>
        <FormControl size="small" sx={{ flexGrow: 1, flexBasis: 0, backgroundColor: "white" }}>
          <Autocomplete
            label="Management (L3)"
            value={division3}
            onChange={(_e, values)=>handleChangeDivision3(values)}
            size="small"
            ChipProps={{
              variant: "contained",
              color: "primary",
            }}
            options={[...division3Options]?.sort((a, b) => a.localeCompare(b)) || []}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Management (L3)"
                placeholder="Management (L3)"
              />
            )}
            disabled={division3Options.length === 0}
          />
        </FormControl>
        <FormControl size="small" sx={{ flexGrow: 1, flexBasis: 0, backgroundColor: "white" }}>
          <Autocomplete
            label="Management (L4)"
            value={division4}
            onChange={(_e, values)=>handleChangeDivision4(values)}
            size="small"
            ChipProps={{
              variant: "contained",
              color: "primary",
            }}
            options={[...division4Options]?.sort((a, b) => a.localeCompare(b)) || []}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Management (L4)"
                placeholder="Management (L4)"
              />
            )}
            disabled={division4Options.length === 0}
          />
        </FormControl>
      </Box>
    </div>
  );
};

export default ManagementFilter;
