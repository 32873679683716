import React from "react";

import HorizontalChartCard from "pages/LocationInsights/components/charts/HorizontalChartCard";
import { getPercentageString } from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/LocationInsightsInternalDetail/utils";

const OtherLocations = ({ data }) => {
  const totalValue = Object.values(data).reduce((acc, curr) => acc + Number(curr), 0);
  const chartData = data ? Object.entries(data).map(([key, value]) => ({
    label: key,
    value: +value,
    additionalValue: getPercentageString(+value, totalValue),
  })).sort((a, b) => b.value -a.value) : [];

  return (
    <HorizontalChartCard header="Other locations talent volume" data={chartData} maxCount={13} color="#6929C4" />
  );
};

export default OtherLocations;
