import { DoDisturbOn, ThumbDown, ThumbUp } from "@mui/icons-material";

const favorabilityIcons = {
  "Favorable": <ThumbUp className="!fill-green-600 !text-xs" />,
  "Unfavorable": <ThumbDown className="!fill-red-600 !text-xs" />,
  "Neutral": <DoDisturbOn className="!fill-gray-200 !text-xs" />,
};

const favorabilityOptions = ["Favorable", "Unfavorable", "Neutral", "No favourability"];

export const CommentThemeCard = ({ title, description, isCommentsLoading, comments }) => {

  const favorabilityCounts = comments.reduce((acc, comment) => {
    if (["Favorable", "Unfavorable", "Neutral"].includes(comment.favorability)) {
      acc[comment.favorability] = (acc[comment.favorability] || 0) + 1;
    } else {
      acc["No favourability"] = (acc["No favourability"] || 0) + 1;
    }
    return acc;
  }, {});

  return <div className="p-5 bg-white rounded-xl hover:border-black h-full box-border border border-b-2 border-transparent cursor-pointer flex flex-col justify-between">
    <div>
      <h2 className="text-xl font-extrabold mb-3">{title}</h2>
      <p>{description}</p>
    </div>
    {
      isCommentsLoading || comments.length > 0 ? <div className="pt-3 border-t border-gray-200 mt-3">
        {
          isCommentsLoading ? <div role="status" className="w-full animate-pulse">
            <div className="h-2.5 bg-gray-200 rounded-full w-[1/2] mb-4"></div>
            <div className="h-2 bg-gray-200 rounded-full w-full mb-2.5"></div>
            <span className="sr-only">Loading...</span>
          </div> : null
        }

        {
          comments.length > 0 ? <div>
            <p className="underline">See {comments.length} relevant comments {">"} </p>
            <div className="flex items-center gap-2 mt-2 text-xs">
              {
                favorabilityOptions.map((key) => {
                  return <div key={key} className="flex items-center gap-1 mt-2">
                    {favorabilityIcons[key]}
                    <p>{key}</p>
                    <span className="px-1 py-px bg-[#F2F2F2] flex items-center justify-center">{favorabilityCounts[key] ?? 0}</span>
                  </div>;
                })
              }
            </div>
          </div> : null
        }
      </div> : null
    }
  </div>;
};
