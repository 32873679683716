import React from "react";

import HorizontalChartCard from "pages/LocationInsights/components/charts/HorizontalChartCard";
import { useParams, useSearchParams } from "react-router-dom";

import { getPercentageString } from "../../utils";

const TeamDistributionOverview = ({ data }) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();
  const chartData = data?.value ? Object.entries(data.value).map(([key, value]) => ({
    category: key,
    value: Math.round(+value),
  })) : [];

  const totalValue = chartData.reduce((acc, item) => acc + item.value, 0);

  const teamDistributionData = chartData.map((item) => ({
    label: item.category,
    value: item.value,
    additionalValue: getPercentageString(item.value, totalValue),
  })).sort((a, b) => b.value - a.value);
  
  return (
    <HorizontalChartCard 
      header="Team distribution"
      subheader={Array.from(currentParams.values()).join(" - ")}
      data={teamDistributionData} color="#4589FF"
      className="mt-4"
    />
  );
};

export default TeamDistributionOverview;
