import React from "react";

import HorizontalChartCard from "pages/LocationInsights/components/charts/HorizontalChartCard";
import { useSearchParams, useParams } from "react-router-dom";

import { getPercentageString } from "../../utils";

const getLeadershipDepthData = (data) => {
  const { "Directors": directors, "Senior Directors": seniorDirectors, "VPs": vps, "SVPs and above": svpsAndAbove } = data || {};
  const totalValue = (directors ?? 0) + (seniorDirectors ?? 0) + (vps ?? 0) + (svpsAndAbove ?? 0);

  return [
    {
      label: "Directors",
      value: directors,
      additionalValue: getPercentageString(directors, totalValue),
    },
    {
      label: "Senior Directors",
      value: seniorDirectors,
      additionalValue: getPercentageString(seniorDirectors, totalValue),
    },
    {
      label: "VPs",
      value: vps,
      additionalValue: getPercentageString(vps, totalValue),
    },
    {
      label: "SVPs and above",
      value: svpsAndAbove,
      additionalValue: getPercentageString(svpsAndAbove, totalValue),
    }
  ];
};

const LeadershipDepthOverview = ({
  data 
}) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { "Individual Contributors": _individual, "Managers": _manager, ...rest } = data?.value || {};

  return (
    <HorizontalChartCard
      header="Leadership depth"
      subheader="Directors and above"
      data={getLeadershipDepthData(rest)}
      color="#BE95FF"
      className="mt-4"
      url={`/dashboard/${id}/location-insights/internal-view/leadership-depth?${currentParams.toString()}`}
    />
  );
};

export default LeadershipDepthOverview;
