import React, { useLayoutEffect, useState } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import dayjs from "dayjs";
import { ColorTheme1 } from "theme/chart";

const ForecastedHeadcount = ({ teamData, locationData, location }) => {
  const [level, setLevel] = useState("autodesk");
  
  if (!teamData && !locationData) return null;

  return <div>
    <div className="flex items-center mb-1">
      <p className="text-xl font-extrabold mr-6">Budgeted headcount</p>
      <ToggleButtonGroup size="small">
        <ToggleButton value="autodesk" selected={level === "autodesk"} onClick={() => setLevel("autodesk")}>Autodesk</ToggleButton>
        <ToggleButton value="team" selected={level === "team"} onClick={() => setLevel("team")}>Team</ToggleButton>
      </ToggleButtonGroup>
    </div>
    <div className="flex items-center mb-4">
      <div className="h-1 w-6 bg-black" />
      <p className="ml-1 text-black/60 ">Actual</p>
      <div className="h-1 w-3 bg-[#ccc] ml-4" />
      <div className="h-1 w-3 bg-[#ccc] ml-1" />
      <p className="ml-1 text-black/60 ">Projection</p>
    </div>
    <ForecastedHeadcountChart id="team-forecasted-headcount" data={level === "autodesk" ? teamData: locationData} />
  </div>;
};

const getChartData = (data) => {
  const observed = Object.entries(data.observed).map(([key, value]) => ({ date: dayjs(key).toDate().getTime(), observed: +value })).sort((a, b) => a.date - b.date);
  const projection = Object.entries(data.projected).map(([key, value]) => ({ date: dayjs(key).toDate().getTime(), projection: +value })).sort((a, b) => a.date - b.date);
  return [
    ...observed,
    { ...observed.at(-1), projection: observed.at(-1).observed },
    ...projection
  ].sort((a, b) => a.date - b.date);
};

export default ForecastedHeadcount;

export const ForecastedHeadcountChart = ({ data, id }) => {

  const chartData = getChartData(data);

  // This code will only run one time
  useLayoutEffect(() => {
    let root = am5.Root.new(id);

    root.setThemes([ColorTheme1.new(root)]);
    root._logo.dispose();

    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      layout: root.verticalLayout,
    }));

    chart.get("colors").set("step", 3);


    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);


    let xRenderer = am5xy.AxisRendererX.new(root, { 
      minorGridEnabled: true,
      minGridDistance: 60 
    });
    xRenderer.labels.template.setAll({
      fill: am5.color("#666666"),
      textAlign: "center",
      fontSize: 12,
    });
    xRenderer.grid.template.set("visible", false);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      maxDeviation: 0.3,
      baseInterval: {
        timeUnit: "month",
        count: 1
      },
      gridIntervals: [{
        timeUnit: "month",
        count: 1
      }, {
        timeUnit: "year",
        count: 1
      }],
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }));

    xAxis.get("dateFormats")["month"] = "MMM yy";

    const yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.labels.template.setAll({
      fill: am5.color("#666666"),
      textAlign: "center",
      fontSize: 12,
    });
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0.3,
      renderer: yRenderer
    }));


    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(field, name, dashed) {
      let series = chart.series.push(am5xy.LineSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: field,
        valueXField: "date",
        stroke: dashed ? am5.color("#ccc") :am5.color("#000"),
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          getFillFromSprite: true,
          labelText: "[bold]{name}[/]\n{valueX}: [bold]{valueY}[/]",
        })
      }));
  
      /* series.get("tooltip").get("background").setAll({
        fillOpacity: 0.7,
        pointerBaseWidth: 0
      }); */
  
      series.strokes.template.setAll({
        strokeWidth: 2
      });
  
      if (dashed) {
        series.strokes.template.set("strokeDasharray", [5, 3]);
      }
  
      series.data.setAll(chartData);
      series.appear(1000);
  
      return series;
    }

    createSeries("observed", "Observed");
    createSeries("projection", "Projection", true);


    // Set date fields
    // https://www.amcharts.com/docs/v5/concepts/data/#Parsing_dates
    root.dateFormatter.setAll({
      dateFormat: "yyyy-MM-dd",
      dateFields: ["valueX"]
    });


    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <Box id={id} style={{ width: "100%", height: "400px", marginLeft: "auto", marginRight: "auto" }}></Box>
  );
};
