import React, { useEffect, useState } from "react";

import { Box, CardContent, Grid, Typography, Divider, Stack, Chip } from "@mui/material";
import { CustomChip } from "components/CustomChip";
import comingSoonModuleImage from "img/genie-on-work.svg";
import pptImage from "img/powerpoint-logo.svg";
import AttritionDateRange from "pages/Dashboards/InsightsGenie/DateRange/AttritionDateRange";
import HeadCountDates from "pages/Dashboards/InsightsGenie/DateRange/HeadCountDates";
import DomainSelect from "pages/Dashboards/InsightsGenie/DomainSelect/DomainSelect";
import DownloadExcel from "pages/Dashboards/InsightsGenie/DownloadExcel/DownloadExcel";
import JobStatusDialog from "pages/Dashboards/InsightsGenie/JobStatus/JobStatusDialog";
import GeoSelectOptions from "pages/Dashboards/InsightsGenie/SelectOptions/GeoSelectOptions";
import OrgSelectOptions from "pages/Dashboards/InsightsGenie/SelectOptions/OrgSelectOptions";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useGetInsightsGenieDomainsQuery, useLazyGetInsightsGenieFiltersQuery } from "store/slices/insightsGenieSlice";

const InsightGenie = () => {
  const { data, isLoading } = useGetInsightsGenieDomainsQuery({ page_name: "insights-genie" });
  const [currentParams, setCurrentParams] = useSearchParams();
  const domain = currentParams.get("domain");
  const dates = currentParams.get("dates");
  const startDate = currentParams.get("startdate");
  const annualized = currentParams.get("annualized");
  const [dialog, setDialog] = useState({ show: false, jobId: null });
  const [filters, setFilters] = useState({});

  const domains = data?.["insights-genie"]?.domains_updated || [];
  const isDomainActive = domains.some((item) => item.name === domain && item.is_active);

  const [getFilters, { isLoading: isFilterLoading }] = useLazyGetInsightsGenieFiltersQuery();


  useEffect(() => {
    // poll jobIds saved in localstorage
    if (domain === "Attrition" && annualized == null) {
      currentParams.set("annualized", "true");
      setCurrentParams(currentParams);
    }
  }, []);

  useEffect(() => {

    if (!domain || !isDomainActive) {
      return;
    }

    const page_name = `insights-genie-${domain}`;
    let filter_name;
    if (domain === "Employee Insights") {
      const domainDate = data?.["insights-genie"]?.[domain]?.[0] ?? "";
      filter_name = domainDate.split(" ").join(" - ");
    } else if (domain === "Attrition") {
      filter_name = startDate;
    } else {
      filter_name = dates;
    }

    if (!filter_name) { 
      return;
    }

    const { unwrap, abort } = getFilters({ page_name, filter_name });

    unwrap().then((response) => {
      setFilters(response?.[page_name]?.[filter_name] ?? {});
    });

    return () => {
      abort();
    };

  }, [domain, dates, startDate, isDomainActive]);

  if (isLoading) {
    return (
      <Box className="flex justify-center items-center" py={8} height="calc(100vh - 148px)">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Box minHeight="calc(100vh - 200px)">
      <Box sx={{ mt: 8, position: "relative", borderRadius: "40px 40px 0 0" }}>
        <Box sx={{
          background: "linear-gradient(90deg, #FF8F6B 39.38%, #FFF 133.48%)",
          padding: "32px 48px",
          borderRadius: "40px 40px 0 0",
        }}>
          <span>Get a standard presentation for a better data storytelling</span>
          <img src={pptImage} alt="ppt" style={{ width: "150px", zIndex: 10, position: "absolute", right: 64, top: -16 }} />
        </Box>
        <CardContent sx={{ p: 4, backgroundColor: "#f9f9f9", position: "relative" }}>
          <Grid container sx={{ backgroundColor: "white", borderRadius: "32px" }}>
            <Grid item md={4} sx={{ p: 6, borderRight: "1px solid #e5e5e5" }}>
              <DomainSelect domains={data["insights-genie"]?.domains_updated} data={data["insights-genie"] ?? {}} />
            </Grid>
            {domain && domain !== "Employee Insights" && isDomainActive ? <>
              <Grid item md={4} sx={{ p: 6, borderRight: "1px solid #e5e5e5" }}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="h6" fontWeight={800} marginBottom={4}>
                  Date and Geo
                  </Typography>
                  {
                    domain === "Headcount" ?
                      <>
                        <HeadCountDates />
                        <Divider sx={{ my: 4 }} />
                      </>
                      : null
                  }
                  {
                    domain === "Attrition" ?
                      <>
                        <Stack direction="row" spacing={1}>
                          <CustomChip 
                            label="Annualised" 
                            isActive={annualized === "true"} 
                            onClick={() => { currentParams.set("annualized", "true"); setCurrentParams(currentParams); }}
                          />

                          <CustomChip 
                            label="Non-annualised" 
                            isActive={annualized !== "true"} 
                            onClick={() => { currentParams.set("annualized", "false"); setCurrentParams(currentParams); }}
                          />
                        </Stack>
                        <AttritionDateRange />
                      </>
                      : null
                  }
                  <GeoSelectOptions data={filters?.geo ?? {}} isLoading={isFilterLoading} />
                </Box>
              </Grid>
            </> : null
            }
            {domain && isDomainActive ? <Grid item md={4} sx={{ p: 6 }}>
              <OrgSelectOptions data={filters?.division ?? {}} />
            </Grid> : null }
            {
              domain && !isDomainActive ? 
                <Grid item md={8} 
                  sx={{ 
                    backgroundImage: `url(${comingSoonModuleImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}>
                  <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", backdropFilter: "blur(10px)", flexDirection: "column" }}>
                    <Typography variant="h4" fontWeight={800}>
                      Insight genie is at work
                    </Typography>
                    <Typography variant="h6" display="block">
                      Check back soon
                    </Typography>
                  </Box>
                </Grid>
                : null
            }
          </Grid>
          <Box display="flex" alignItems="center" justifyContent="end">
            <DownloadExcel setDialog={setDialog} disabled={!isDomainActive} />
          </Box>
          {dialog.show ? <JobStatusDialog jobId={dialog.jobId} onClose={() => setDialog({ ...dialog, show:false })}/> : null }
        </CardContent>
        <Box />
      </Box>
    </Box>
  );
};

export default InsightGenie;
