import React from "react";

import HorizontalChartCard from "pages/LocationInsights/components/charts/HorizontalChartCard";
import { getPercentageString } from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/LocationInsightsInternalDetail/utils";

const ActiveEmployerRanking = ({ data }) => {
  const totalValue = data.reduce((acc, curr) => acc + Number(curr.demandCount), 0);

  const chartData = data ? data
    .map(({ name: key, demandCount: value }) => ({ label: key, value: +Number(value).toFixed(2), additionalValue: getPercentageString(Number(value), totalValue) }))
    .sort((a, b) => b.value -a.value)
    : [];

  return (
    <HorizontalChartCard header="Active employers" subheader="Companies with similar job postings" data={chartData} color="#005D5D" maxCount={15} />
  );
};

export default ActiveEmployerRanking;
