import { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import { OpenInNew } from "@mui/icons-material";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { ColorTheme10 } from "theme/chart";

/* interface PieChartCardProps {
  header: string;
  subheader: string;
  data: {
    label: string;
    value: string | number;
    additionalValue: string | number;
    color: string;
  }[];
} */
const PieChartCard = ({ header, subheader, data = [], className, url }) => {
  const navigate = useNavigate();
  return (
    <div onClick={() => {
      if (url) {
        navigate(url);
      }
    }} className={clsx("flex flex-col bg-white rounded-3xl p-6 border-[#e5e5e5] border group", { "hover:border-black cursor-pointer": url }, className)}>
      <div className="border-b border-[#e5e5e5] pb-2 mb-2">
        <p className={clsx("font-bold flex items-center gap-1", {
          "group-hover:underline": url,
        })}>
          {header}
          <div className={clsx("hidden", { "group-hover:block": url })}><OpenInNew style={{ fontSize: "16px" }} /></div>
        </p>
        <p className="text-sm text-black/60 line-clamp-1">{subheader}</p>
      </div>
      <div>
        <PieChart id={header} data={data} />
      </div>
      <div className="mt-4">
        {
          data.map((item) => {
            return <div key={item.label} className="flex items-center pb-1 mb-1">
              <div className="flex items-center gap-1">
                <div className="w-4 h-4 rounded-full" style={{ backgroundColor: item.color }}></div>
                <p className="text-sm">{item.label}</p>
              </div>
              <div className="ml-auto flex items-center gap-1">
                <p className="text-xs text-black/60">{item.additionalValue}</p>
                <p className="text-sm font-bold">{item.value}</p>
              </div>
            </div>;
          })
        }
      </div>
    </div>
  );
};

export default PieChartCard;


const PieChart = ({ id, data }) => {

  const chartId = id || "pie-chart";
  useLayoutEffect(() => {
    const root = am5.Root.new(chartId);
        
    root.setThemes([ColorTheme10.new(root)]);
    root._logo.dispose();

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5.percent(90),
        innerRadius: am5.percent(85)
      })
    );

    let series = chart.series.push(am5percent.PieSeries.new(root, {
      name: chartId,
      valueField: "value",
      categoryField: "label",
      fillField: "color"
    }));

    series.data.setAll(data);
    series.labels.template.set("visible", false);
    series.ticks.template.set("visible", false);

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id={chartId} className="w-full h-80" />;
};
