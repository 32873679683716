import React from "react";

import HorizontalChartCard from "pages/LocationInsights/components/charts/HorizontalChartCard";
import { getPercentageString } from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/LocationInsightsInternalDetail/utils";

const SkillsRanking = ({ data }) => {

  const totalValue = data.reduce((acc, curr) => acc + Number(curr.demand), 0);

  const chartData = data.map((item) => ({
    label: item.label,
    value: +item.demand,
    additionalValue: getPercentageString(Number(item.demand), totalValue),
  })).sort((a, b) => b.value - a.value);

  return (
    <HorizontalChartCard header="Skills ranking" data={chartData} color="#1292E8" maxCount={15} />
  );
};

export default SkillsRanking;
