import { useEffect, useRef } from "react";

import { Box, Tab, Tabs, Divider } from "@mui/material";
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import useMenus from "./useMenus";

const NavBar = () => {
  const items = useMenus();
  const navigate = useNavigate();
  const activeMenu = items.find(item => item.isActive);
  return <Box className="px-8">
    <Tabs value={activeMenu?.to}>
      {
        items.map(item => {
          return <Tab key={item.title} value={item.to} label={item.title} onClick={() => navigate(item.to)}/>;
        })
      }
    </Tabs>

    <Divider />
  </Box>;
};


const LocationInsightsLandingPage = () => {
  const { pathname } = useLocation();
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) containerRef.current.scrollTop = 0;
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box>
      <Header pageName="Location Insights" />
      <Box className="flex flex-col">
        <NavBar />
        <Box height="calc(100vh - 56px)" overflow="auto" ref={containerRef}>
          <Outlet />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default LocationInsightsLandingPage;

