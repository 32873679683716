import React, { useState } from "react";

import { ArrowRight } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import clsx from "clsx";

/* interface ColumnGroups {
  key: string;
  label: string;
  color: string;
  isSortable: boolean;
  sortFn?: (a: any, b: any, order: 'asc' | 'desc') => number;
}

interface DataTableProps {
  data: Record<string, any>[];
  groups?: ColumnGroup[];
} */

// type Order = 'asc' | 'desc';

const defaultSortFn = (a, b, order) => {
  if (order === "asc") {
    return a - b;
  }
  return b - a;
};

export const DataTable = ({ data, groups, hasAdditionalInfo, onRowClick }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Assuming all data objects are of same shape
  const allColumns = groups ? groups : Object.keys(data?.[0] ?? {}).map((key) => ({
    key,
    label: key.slice(0, 1).toUpperCase() + key.slice(1),
    isSortable: true,
    sortFn: defaultSortFn,
  }));

  const dataLookup = allColumns.reduce((acc, column) => {
    acc[column.key] = column;
    return acc;
  }, {});

  const sortedData = [...data].sort((a, b) => {
    if (orderBy) {
      const aVal = a[orderBy];
      const bVal = b[orderBy];
      const isSortable = dataLookup[orderBy].isSortable;
      if (isSortable) {
        return dataLookup[orderBy].sortFn ? dataLookup[orderBy].sortFn(aVal, bVal, order) : defaultSortFn(aVal, bVal, order);
      }
    }
    return 0;
  });

  if (!data || data.length === 0) {
    return <div className="flex items-center justify-center w-full h-96 border border-[#e5e5e5] rounded-3xl">
      No data available
    </div>;
  }

  return (
    <div className="padding-2 rounded-3xl border border-[#e5e5e5]">
      <TableContainer className="overflow-x-auto rounded-3xl">
        <Table stickyHeader className="min-w-full rounded-xl">
          <TableHead>
            <TableRow>
              {allColumns.map((column) => (
                <TableCell
                  key={column.key}
                  style={{ borderBottom: column.color ? `3px solid ${column.color}` : "" }}
                >
                  {column.isSortable ? (
                    <TableSortLabel
                      active={orderBy === column.key}
                      direction={orderBy === column.key ? order : "asc"}
                      onClick={() => handleRequestSort(column.key)}
                    >
                      <span className="font-bold text-base">{column.label}</span>
                    </TableSortLabel>
                  ) : (
                    <span className="font-bold text-base">{column.label}</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={index}
                  className={clsx("transition-colors group", { "hover:bg-gray-50": !row.hightlight, "cursor-pointer": hasAdditionalInfo })}
                  onClick={() => onRowClick && onRowClick(row)}
                >
                  {allColumns.map((column, index) => {
                    return (
                      <TableCell key={column.key}
                        className={clsx({
                          "group-hover:underline": hasAdditionalInfo && index === 0,
                        })}
                        style={{ background: row.hightlight && row.highlightColor, fontSize: "14px" }}
                      >
                        <div className="flex items-center">
                          <div>{row[column.key]}</div>
                          {hasAdditionalInfo && index === allColumns.length - 1 && (
                            <div className="text-gray-400 group-hover:visible ml-auto invisible">
                              <ArrowRight />
                            </div>
                          )}
                        </div>
                      </TableCell>
                    ); 
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex justify-center">
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};
