import React from "react";

import HorizontalChartCard from "pages/LocationInsights/components/charts/HorizontalChartCard";
import { getPercentageString } from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/LocationInsightsInternalDetail/utils";

const TitlesRanking = ({ data }) => {
  const totalValue = data.reduce((acc, curr) => acc + Number(curr.demand), 0);

  const chartData = data.map((item) => ({
    label: item.label,
    value: +item.demand,
    additionalValue: getPercentageString(Number(item.demand), totalValue),
  })).sort((a, b) => b.value - a.value);

  return (
    <HorizontalChartCard header="Titles ranking" data={chartData} color="#4589FF" maxCount={15} />
  );
};

export default TitlesRanking;
