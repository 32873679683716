import { useMemo } from "react";

import { useSearchParams, useParams, useLocation } from "react-router-dom";

export const getGroupedMenus = (id) => ([
  { 
    id: "tp",
    name: "Talent pool expansion", 
    description: "Broaden access to talent for improved recruitment and team balance​", 
    menus: [
      {
        title: "Headcount",
        description: "View detailed headcount breakdown of employees, contingents, interns, and team comparison globally",
        to: `/dashboard/${id}/location-insights/internal-view/headcount?s=tp`
      },
      {
        title: "Attrition",
        description: "View and compare team attrition breakdown by employee tenure and attrition type",
        to: `/dashboard/${id}/location-insights/internal-view/attrition?s=tp`
      },
      {
        title: "Talent Supply and Demand",
        description: "View external talent supply and market demand​",
        to: `/dashboard/${id}/location-insights/external-view/detail/supply-and-demand?s=tp`
      },
      {
        title: "Hiring Difficulty",
        description: "View the hiring difficulty for job title based on the location and other factors​​",
        to: `/dashboard/${id}/location-insights/external-view/detail/hiring-difficulty?s=tp`
      },
    ] 
  },
  { 
    id: "cte",
    name: "Value-driven Market", 
    description: "Focus on competitive talent costs for operational efficiency​​", 
    menus: [
      {
        title: "Relative Compensation",
        to: `/dashboard/${id}/location-insights/external-view/detail/cost-of-labor?s=cte`
      },
      {
        title: "Office Location Summary",
        to: `/dashboard/${id}/location-insights/hub-summary?s=cte`
      },
      {
        title: "Headcount",
        to: `/dashboard/${id}/location-insights/internal-view/headcount?s=cte`
      },
      {
        title: "Open and Budgeted Roles",
        to: `/dashboard/${id}/location-insights/internal-view/recruitment?s=cte`
      },
    ] 
  },
  { 
    id: "tzo",
    name: "Timezone optimization", 
    description: "View team's working timezone and compare working-hour overlaps​​", 
    menus: [
      {
        title: "Timezone",
        to: `/dashboard/${id}/location-insights/internal-view/timezone?s=tzo`
      },
      {
        title: "Open and Budgeted Roles",
        to: `/dashboard/${id}/location-insights/internal-view/recruitment?s=tzo`
      },
      {
        title: "Office Location Summary",
        to: `/dashboard/${id}/location-insights/hub-summary?s=tzo`
      }
    ] 
  },
  { 
    id: "cl",
    name: "Co-location", 
    description: "Foster cross-team collaboration and career growth with co-located teams", 
    menus: [
      {
        title: "Timezone",
        to: `/dashboard/${id}/location-insights/internal-view/timezone?s=cl`
      },
      {
        title: "Office Location Summary",
        to: `/dashboard/${id}/location-insights/hub-summary?s=cl`
      },
      {
        title: "External Market Overview",
        to: `/dashboard/${id}/location-insights/external-view?s=cl`
      }
    ] 
  },
  { 
    id: "dtr",
    name: "Divest to reinvest", 
    description: "Divest from current locations and reallocate resources​​", 
    menus: [
      {
        title: "Headcount",
        to: `/dashboard/${id}/location-insights/internal-view/headcount?s=dtr`
      }, 
      {
        title: "External Market Overview",
        to: `/dashboard/${id}/location-insights/external-view?s=dtr`
      },
      {
        title: "Open and Budgeted Roles",
        to: `/dashboard/${id}/location-insights/internal-view/recruitment?s=dtr`
      },
      {
        title: "Office Location Summary",
        to: `/dashboard/${id}/location-insights/hub-summary?s=dtr`
      }
    ] 
  },
  { 
    id: "lb",
    name: "Leadership bench", 
    description: "Position leaders in key regions to boost organizational effectiveness & engagement​​", 
    menus: [
      {
        title: "Leadership Depth",
        to: `/dashboard/${id}/location-insights/internal-view/leadership-depth?s=lb`
      },
      {
        title: "Office Location Summary",
        to: `/dashboard/${id}/location-insights/hub-summary?s=lb`
      },
      {
        title: "Open and Budgeted Roles",
        to: `/dashboard/${id}/location-insights/internal-view/recruitment?s=lb`
      }
    ] 
  }
]);

const getUrl = (pathname, url, params) => {
  if((pathname.includes("external-view") && !url.includes("external-view")) 
    || (pathname.includes("internal-view") && !url.includes("internal-view"))) {
    const newParams = new URLSearchParams();
    const geo = params.get("geo");
    if(geo) {
      newParams.set("geo", geo);
    }
      
    const country = params.get("country");
    if(country) {
      newParams.set("country", country);
    }
      
    const city = params.get("city");
    if(city) {
      newParams.set("city", city);
    }
    return `${url}&${newParams.toString()}`;
  }

  return `${url}&${params.toString()}`;
};
  
const useGroupedMenus = () => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();
  const { pathname } = useLocation();
  const groupedMenus = useMemo(() => getGroupedMenus(id), [id]);

  return groupedMenus.map(group => {
    return { 
      ...group, 
      menus: group.menus.map(menu => {
        return {
          ...menu,
          to: getUrl(pathname, menu.to, currentParams)
        };
      }) 
    };
  });
};

export const useJourney = () => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();
  const { pathname } = useLocation();
  const groupedMenus = useMemo(() => getGroupedMenus(id), [id]);

  const section = currentParams.get("s")?.replace("?", "");
  
  if (section) {
    const sectionMenus = groupedMenus.find(group => group.id === section)?.menus ?? [];
    const currentIndex = sectionMenus.findIndex(menu => {
      return menu.to.startsWith(pathname);
    });

    if (currentIndex !== -1) {
      let prev = currentIndex > 0 ? sectionMenus[currentIndex - 1] : null;
      let next = currentIndex < sectionMenus.length - 1 ? sectionMenus[currentIndex + 1] : null;

      prev = prev ? {
        ...prev,
        to: getUrl(pathname, prev.to, currentParams)
      } : null;

      next = next ? {
        ...next,
        to: getUrl(pathname, next.to, currentParams)
      } : null;
      
      return { prev, next };
    } else {
      return {};
    }
  } else {
    return {};
  }
};

export default useGroupedMenus;
