
import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import MoreInformationPopover from "components/MoreInformationPopover/MoreInformationPopover";
import HeaderCard from "pages/LocationInsights/components/HeaderCard";
import { useOutletContext } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import ExternalCostOfLabor from "./ExternalCostOfLabor";
import InternalCostOfLabor from "./InternalCostOfLabor";
import helpText from "../helpText";

const CostOfLabor = () => {
  const { externalData, isLoading } = useOutletContext();

  if(isLoading) {
    return (
      <Grid item md={12}>
        <Box className="flex items-center justify-center w-full" sx={{ height: "calc(100vh - 400px)" }}>
          <BeatLoader color="#5C5470" />
        </Box>
      </Grid>
    );
  }
  
  if(!externalData || externalData.length !== 1) {
    return (
      <Box className="w-full" sx={{ height: "calc(100vh - 400px)" }}>
        <div className="w-3/4 mx-auto">
          <Typography mt={4} variant="body1" textAlign="center" fontWeight="bold" fontSize="24px">
            No data available. Try selecting or changing the location and job title filters
          </Typography>
        </div>
      </Box>
    );
  }

  const data = externalData[0];
  const hireDifficultyData = data.rel_cost_labor && data.score_rel_cost_labor ? { value: data.rel_cost_labor, score: data.score_rel_cost_labor } : null;
  const autodeskHireDifficultyData = data.rel_cost_labor_adsk && data.score_rel_cost_labor_adsk ? { value: data.rel_cost_labor_adsk, score: data.score_rel_cost_labor_adsk } : null;
  
  return (
    <div>
      <HeaderCard 
        question="How expensive is it to hire at this location?"
        info={<div>
          <p className="mb-1">This page shows the compensation at this location compared to other locations with demand (Market) or compensation guidance (Autodesk) for this role</p>
          <MoreInformationPopover helpText={helpText.costOfLabor}/>
        </div>}
      />
      <Grid container mt={4} columnSpacing={4}>
        <Grid item xs={12} lg={4}>
          <ExternalCostOfLabor data={hireDifficultyData ?? {}} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <InternalCostOfLabor data={autodeskHireDifficultyData ?? {}} />
        </Grid>
      </Grid>
    </div>
  );
};

export default CostOfLabor;
