import React from "react";

import { Language } from "@mui/icons-material";
import { NoDataCard } from "pages/LocationInsights/components/NoDataCard";
import { StatCard } from "pages/LocationInsights/components/StatCard";
import { isObjectEmpty } from "utils/object";


const ExternalCostOfLabor = ({ data }) => {
  
  if (!data || (Array.isArray(data) && data.length === 0) || isObjectEmpty(data)) {
    return <NoDataCard title="Relative compensation - Market" />;
  }

  const { value, score } = data;

  let level = "critical";
  if(score > 2 && score < 5) {
    level = "low";
  } else if(score > 4 && score < 7) {
    level = "medium";
  } else if (score >= 7 ) {
    level = "high";
  }

  return (
    <StatCard 
      theme="dark"
      mainStat={value}
      mainStatLabel={{ title: "Relative compensation - Market" }}
      level={level}
      levelConfig={{ inverse: true, solid: true }}
      subStats={[{
        title: `${score}/10`,
        value: "Market benchmark",
        icon: <Language sx={{ color: "white", fontSize: "14px" }} />
      }]}
    />
  );
};

export default ExternalCostOfLabor;
