import React from "react";

import { Chip } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const CurrentFilters = () => {
  const [currentParams, setCurrentParams] = useSearchParams();

  const teamFilters = [
    { key: "division", value: currentParams.get("division") }, 
    { key: "l2", value: currentParams.get("l2") }, 
    { key: "l3", value: currentParams.get("l3") }, 
    { key: "l4", value: currentParams.get("l4") }
  ].filter(filter => filter.value);
  const locationFilters = [
    { key: "geo", value: currentParams.get("geo") },
    { key: "country", value: currentParams.get("country") },
    { key: "city", value: currentParams.get("city") },
    { key: "site", value: currentParams.get("site") }
  ].filter(filter => filter.value);


  const handleDelete = (key) => {
    currentParams.delete(key);
    setCurrentParams(currentParams);
  };

  return (
    <div className="flex flex-wrap gap-1 items-start">
      { locationFilters.length > 0 ? <Chip label="Location" sx={{ backgroundColor: "#666", color: "white" }} /> : null }
      
      {locationFilters.map((filter) => (
        <Chip
          key={filter.key}
          label={filter.value}
          onDelete={() => handleDelete(filter.key)}
        />
      ))}

      { teamFilters.length > 0 ? <Chip label="Team" sx={{ backgroundColor: "#666", color: "white", marginLeft: 2 }} /> : null }

      {teamFilters.map((filter) => (
        <Chip
          key={filter.key}
          label={filter.value}
          onDelete={() => handleDelete(filter.key)}
        />
      ))}
    </div>
  );
};

export default CurrentFilters;
