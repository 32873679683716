import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import MoreInformationPopover from "components/MoreInformationPopover/MoreInformationPopover";
import HeaderCard from "pages/LocationInsights/components/HeaderCard";
import { useOutletContext } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import ActiveEmployerRanking from "./ActiveEmployerRanking";
import CompetitiveConcentration from "./CompetitiveConcentration";
import Competitors from "./Competitors";
import helpText from "../helpText";


const TalentCompetitionDetail = () => {
  const { externalData, isLoading } = useOutletContext();

  if(isLoading) {
    return (
      <Grid item md={12}>
        <Box className="flex items-center justify-center w-full" sx={{ height: "calc(100vh - 400px)" }}>
          <BeatLoader color="#5C5470" />
        </Box>
      </Grid>
    );
  }
  
  if(!externalData || externalData.length !== 1) {
    return (
      <Box className="w-full" sx={{ height: "calc(100vh - 400px)" }}>
        <div className="w-3/4 mx-auto">
          <Typography mt={4} variant="body1" textAlign="center" fontWeight="bold" fontSize="24px">
            No data available. Try selecting or changing the location and job title filters
          </Typography>
        </div>
      </Box>
    );
  } 

  const activeEmployerRanking = externalData[0].details_actively_hiring;
  const competitors = externalData[0].details_competition;
  const competitiveConcentrationData = externalData[0].competitiveConcentration;
  
  return (
    <div>
      <HeaderCard 
        question="What is the competitive landscape for talent in this location?"
        info={<div>
          <p className="mb-1"><b>TalentNeuron</b> defines competitive concentration as to the level of competition one faces in trying to hire a candidate in a particular location</p>
          <MoreInformationPopover helpText={helpText.talentCompetition}/>
        </div>}
      />
      <Grid container mt={4} columnSpacing={4}>
        <Grid item xs={12} lg={8}>
          <Box>
            <Typography variant="h4" fontWeight="bold" my={4}>
              Competitive Concentration
            </Typography>
            <CompetitiveConcentration data={competitiveConcentrationData} />
          </Box>
        </Grid>
      </Grid>
      <Grid container mt={4} columnSpacing={4}>
        <Grid item lg={4}>
          <Competitors data={competitors} />
        </Grid>
        <Grid item lg={4}>
          <ActiveEmployerRanking data={activeEmployerRanking} />
        </Grid>
      </Grid>
    </div>
  );
};

export default TalentCompetitionDetail;
