import React from "react";

import clsx from "clsx";
import PieChartCard from "pages/LocationInsights/components/charts/PieChartCard";
import { useSearchParams, useParams } from "react-router-dom";

import { getPercentageString } from "../../utils";

const NAME_MAPPING = {
  requisition_count: "TBH with Open Requisitions",
  tbh_count: "TBH without Open Requisitions",
};

const COLOR_MAPPING = {
  tbh_count: "#4589FF",
  requisition_count: "#EE538B"
};

const RecruitmentOverview = ({
  title,
  colors,
  data,
  className
}) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();

  const chartData = data?.value ? Object.entries(NAME_MAPPING).map(([key, label]) => ({
    label: label,
    value: data.value[key] ? +data.value[key] : 0,
    color: colors?.[key] ?? COLOR_MAPPING[key]
  })): [];

  const totalValue = chartData.reduce((acc, item) => acc + item.value, 0);
  const recruitmentData = chartData.map((item) => ({
    ...item,
    additionalValue: getPercentageString(item.value, totalValue)
  }));

  return (
    <PieChartCard
      header={title}
      data={recruitmentData}
      className={clsx(className)}
      url={`/dashboard/${id}/location-insights/internal-view/recruitment?${currentParams.toString()}`}
    />
  );
};

export default RecruitmentOverview;

