import React from "react";

import { DataTable } from "components/DataTable";

import { columns } from "./columns";
import DownloadExcelButton from "../../../components/DownloadExcelButton";

const InternalViewTable = ({ data }) => {
  return <div className="flex flex-col gap-6 w-full">
    <div className="flex items-center gap-6">
      <p className="text-xl font-extrabold mr-auto">Details at glance</p>
      <DownloadExcelButton data={data} columns={columns} title="Internal View Summary" />
    </div>
    <DataTable data={data} groups={columns} />
  </div>;
};

export default InternalViewTable;
