export const getLocationFromQueryParams = (queryParams) => {
  const geo = queryParams.get("geo");
  const country = queryParams.get("country");
  const city = queryParams.get("city");

  if(!geo && !country && !city) {
    return "All Locations";
  }
  return `${city ? `${city}, ` : ""}${country ? `${country}, ` : ""}${geo ? geo : ""}`;
};

export const getTeamFromQueryParams = (queryParams) => {
  const division = queryParams.get("division");
  const l1 = queryParams.get("l1");
  const l2 = queryParams.get("l2");
  const l3 = queryParams.get("l3");
  const l4 = queryParams.get("l4");

  const teamName = l4 || l3 || l2 || l1 || division;
  return teamName || "Team";
};

export const getLocationNameFromQueryParams = (queryParams) => {
  const geo = queryParams.get("geo");
  const country = queryParams.get("country");
  const city = queryParams.get("city");
  const site = queryParams.get("site");

  const locationName = site || city || country || geo;
  return locationName || "All Locations";
};

export const getPercentageString = (value = 0, totalValue = 1) => {
  return `${((value / (totalValue || 1)) * 100).toFixed(1)}%`;
};

export const getLevelValue = (value) => {
  const val = (value ?? "").toLowerCase();
  if(val === "very high" || value === "high") {
    return "high";
  } else if(val === "moderate" || val === "medium") {
    return "medium";
  } else if(val === "low") {
    return "low";
  } else if(val === "very low") {
    return "critical";
  }
};

export function findOverlappingHours(pairs) {
  function getOverlapHours(start1, end1, start2, end2) {
    const start1Hours = start1.getHours() + start1.getMinutes() / 60;
    const end1Hours = end1.getHours() + end1.getMinutes() / 60;
    const start2Hours = start2.getHours() + start2.getMinutes() / 60;
    let end2Hours = end2.getHours() + end2.getMinutes() / 60;

    // Adjust for overnight span
    if (end2Hours < start2Hours) {
      end2Hours += 24;
    }

    const start = Math.max(start1Hours, start2Hours);
    const end = Math.min(end1Hours, end2Hours);
    const overlap = end - start;
    return overlap > 0 ? Math.round(overlap) : 0;
  }

  let overlap;

  for (let i = 0; i < pairs.length; i++) {
    for (let j = i + 1; j < pairs.length; j++) {
      const start1 = new Date(pairs[i].start);
      const end1 = new Date(pairs[i].end);
      const start2 = new Date(pairs[j].start);
      const end2 = new Date(pairs[j].end);

      overlap = getOverlapHours(start1, end1, start2, end2);
    }
  }

  return overlap;
}

export const getGroupedTimezoneDistribution = (data) => {
  const groupedData = data.reduce((acc, item) => {
    if(!acc[item.timezone]) {
      acc[item.timezone] = {
        ...item,
        base: [item.base],
      };
    } else {
      const existing = acc[item.timezone];
      acc[item.timezone] = {
        ...existing,
        base: [...existing.base, item.base],
        headcount: existing.headcount + item.headcount,
      };
    }
    return acc;
  }, {});

  return Object.values(groupedData).map((item) => {
    return {
      ...item,
      base: item.base.slice(0, 2).join(", ") + (item.base.length > 2 ? ` +${item.base.length - 2} more` : ""),
    };
  });
};
