import React from "react";

import { StatCard } from "pages/LocationInsights/components/StatCard";
import { getLevelValue } from "pages/LocationInsights/LocationInsightsLandingPage/LocationInsightsInternalView/LocationInsightsInternalDetail/utils";
import { useParams, useSearchParams, Link as RouterLink } from "react-router-dom";

export const Supply = ({ data }) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();
  return (
    <StatCard
      theme="dark"
      mainStat={new Intl.NumberFormat("en-US").format(data)}
      mainStatLabel={{ title: "Supply" }}
      url={`/dashboard/${id}/location-insights/external-view/supply-and-demand?${currentParams.toString()}`}
    />
  );
};

export const SupplyDemandRatio = ({ data }) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();
  const supplyDemandRatioLevel = data.split(" ").at(-1);
  return (
    <StatCard
      theme="dark"
      mainStat={data}
      mainStatLabel={{ title: "Supply demand ratio" }}
      level={getLevelValue(supplyDemandRatioLevel)}
      url={`/dashboard/${id}/location-insights/external-view/supply-and-demand?${currentParams.toString()}`}
    />
  );
};

export const HiringDifficulty = ({ data }) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();
  return (
    <StatCard
      mainStat={data} 
      mainStatLabel={{ title: "Hiring difficulty" }} 
      url={`/dashboard/${id}/location-insights/external-view/hiring-difficulty?${currentParams.toString()}`}
    />
  );
};

export const CompetitiveConcentration = ({ data }) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();
  return (
    <StatCard
      mainStat={data}
      mainStatLabel={{ title: "Competitive concentration" }}
      url={`/dashboard/${id}/location-insights/external-view/talent-competition?${currentParams.toString()}`}
    />
  ); 
};

export const CorporateIncomeTaxRate = ({ data }) => {
  return (
    <StatCard mainStat={data} mainStatLabel={{ title: "Corporate income tax rate" }} />
  ); 
};

export const EnglishProficiency = ({ data }) => {
  const level = data.split(" ").at(-1);
  return (
    <StatCard mainStat={data} mainStatLabel={{ title: "English Proficiency" }} level={level.toLowerCase()} />
  ); 
};
